$(document).on('turbolinks:load', () => {
    let textField = document.getElementById('device-text-field');
    if (textField !== null) {
        // Validate the data in the text field whenever it gets changed
        textField.addEventListener('change', function() {
            var text = textField.value;
            // Valid text only has numbers and commas
            var withoutDigits = text.replace(/[0-9]/g, '');
            var withoutCommas = withoutDigits.replace(/(,)/g, '');
            if (withoutCommas.length > 0) {
                alert("Please only enter numbers and commas into the devices text box");
            }
        });
    }
})