document.addEventListener("turbolinks:load", () => {
  sendCode();
  submitUnlockForm();
});

const sendCode = () => {
  const $sendCodeButton = $("#send-sensitive-data-auth-code");
  let clickCount = 0;
  $sendCodeButton.click((e) => {
    clickCount ++;
    handleDisplay(clickCount, $sendCodeButton);
  });
}

const handleDisplay = (numberOfClicks, sendCodeButton) => {
  if (numberOfClicks === 1) {
    $(".sent-code-info").removeClass("is-hidden");
    $("#divInner").removeClass("is-hidden");
  }

  sendCodeButton.text("Sent!");
  sendCodeButton.append("<span id='send-code-feedback' class='has-margin-left-10'>✅</span>");
  sendCodeButton.parent().addClass("is-disabled");

  setTimeout(() => {
    $("#send-code-feedback").remove();
    sendCodeButton.text("Send New Code");
    sendCodeButton.parent().removeClass("is-disabled");
  }, 2000);
}

const submitUnlockForm = () => {
  const $unlockForm = $("#unlock-form")
  $("#submit-unlock-form").click(() => {
    $unlockForm.submit();
  });
}
