import Config from "../custom/config/constants";

$(document).on('turbolinks:load', () => {
    const $siteDropdown = $("#machine_tags_control_site_id");
    const $siteDiv = $("#machine-tag-site-div");
    const $companyDropdown = $("#machine_tags_control_company_id");
    if ($companyDropdown.length) {
        listenForSiteChange($companyDropdown, $siteDropdown, $siteDiv);
    }
});

const listenForSiteChange = ($companyDropdown, $siteDropdown, $siteDiv) => {
    $companyDropdown.change((e) => {
        $siteDiv.show();
        let companyId = $(e.target).val();

        $.ajax({
            type: 'GET',
            url: `${Config.apiUrl}companies/get_sites_for_company?apikey=${Config.apiKey}&company_id=${companyId}`,
            dataType: "json",
            success: data => {
                let siteDropdownHtml = "";
                $.each(data.sites, (index, site) => {
                    siteDropdownHtml +=  `<option value="${site["id"]}">${site["name"]}</option>`
                });
                 $siteDropdown.empty().append(siteDropdownHtml);
            },
            error: data => {
                alert("Error trying to retrieve site data. Please refresh the page and try again.");
            }
        });
    });
}