import {closeMenu, openMenu} from "./map/map_filter_toggle";

document.addEventListener("turbolinks:load", () => {
    let filter_button = document.getElementById('filters-button');
    if (filter_button){
        toggleFilters(filter_button);
    }
});

const toggleFilters = (filter_button) => {
    // Opening map filters
    filter_button.onclick = function () {
        if($("#filters-popout-menu").is(":visible")){
            closeMenu('filters-popout-menu');
        }else{
            openMenu('filters-popout-menu');
        }
    }
}