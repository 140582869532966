// the main purpose of the module is to set the site ID
// and the site coordinates to be used elsewhere in the map
// this module should be solely responsible for handling which site
// the map should be working with at any given time
import Config from "../config/constants";
import { mapObject } from './map';
import { locationDataAjaxCall, clashAjaxCall } from './map_ajax';
import { getDevicesForSite } from "../slideout-menu/devices_change";
import { getUsersForSite } from "../slideout-menu/users_change";

let siteId;
let siteCoordinates = [];

// INITIAL SITE ON LOAD
// this ajax request only runs on first page load to set the initial site
const getInitialSite = () => {
  return $.ajax({
    type: 'GET',
    url: `${Config.apiUrl}map_sites?apikey=${Config.apiKey}`,
    dataType: "json",
    success: data => {
      siteId = data.site_data.id;
      siteCoordinates = [data.site_data.longitude, data.site_data.latitude];
    },
    error: data => {
      alert("Error trying to retrieve site data. Please refresh the page and try again.");
    }
  });
}

const updateSiteCoordinates = (siteId) => {
  return $.ajax({
    type: 'GET',
    url: `${Config.apiUrl}map_sites/get_site_coordinates?apikey=${Config.apiKey}&site_id=${siteId}`,
    dataType: "json",
    success: data => {
      return data.coordinates.split(" ")
    },
    error: data => {
      alert("Error trying to retrieve site data. Please refresh the page and try again.");
    }
  });
}

export { siteId, siteCoordinates, updateSiteCoordinates, getInitialSite };
