$(document).on('turbolinks:load', () => {
    let geofence_edit_points_div = $('.geofence-edit-points');
    if (geofence_edit_points_div){
        toggleLatLongs(geofence_edit_points_div);
    }
});

function toggleLatLongs(geofenceEditPointsDiv){
    let toggleButton = $('#manual_entry');
    toggleButton.change(function(){
        if(this.checked){
            geofenceEditPointsDiv.show();
        }else{
            geofenceEditPointsDiv.hide();
        }
    })
}