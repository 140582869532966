import flatpickr from "flatpickr";
import rangePlugin from "flatpickr/dist/plugins/rangePlugin";
import moment from "moment";
import confirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate";

(function ($) {

  if (typeof window.myFlatpickrPlugins === 'undefined') {
    window.myFlatpickrPlugins = [];
  }

  var predefinedRanges = function () {
    return function (fp) {
      const pluginData = {
        ranges: typeof fp.config.ranges !== 'undefined' ? fp.config.ranges : {},
        rangesOnly: typeof fp.config.rangesOnly === 'undefined' || fp.config.rangesOnly,
        rangesAllowCustom: typeof fp.config.rangesAllowCustom === 'undefined' || fp.config.rangesAllowCustom,
        rangesCustomLabel: typeof fp.config.rangesCustomLabel !== 'undefined' ? fp.config.rangesCustomLabel : 'Custom Range',
        rangesNav: $('<ul>').addClass('nav flex-column flatpickr-predefined-ranges'),
        rangesButtons: {}
      };

      /**
       * @param {string} label
       * @returns {jQuery}
       */
      const addRangeButton = function (label) {
        pluginData.rangesButtons[label] = $('<button>')
            .addClass('nav-link btn btn-link')
            .attr('type', 'button')
            .text(label)
        ;
        pluginData.rangesNav.append(
            $('<li>').addClass('nav-item d-grid').append(pluginData.rangesButtons[label])
        );
        return pluginData.rangesButtons[label];
      };

      /**
       * Loop the ranges and check for one that matches the selected dates, adding
       * an active class to its corresponding button.
       *
       * If there are selected dates and a range is not matched, check for a custom
       * range button and set it to active.
       *
       * If there are no selected dates or a range is not matched, check if the
       * rangeOnly option is true and if so hide the calendar.
       *
       * @param {Array} selectedDates
       */
      const selectActiveRangeButton = function (selectedDates) {
        let isPredefinedRange = false;
        pluginData.rangesNav.find('.active').removeClass('active');

        if (selectedDates.length > 0) {
          let startDate = moment(selectedDates[0]);
          for (const [label, range] of Object.entries(pluginData.ranges)) {
            if (startDate.isSame(range[0], 'day')) {
              pluginData.rangesButtons[label].addClass('active');
              isPredefinedRange = true;
              break;
            }
          }
        }

        if (selectedDates.length > 0 &&
            !isPredefinedRange &&
            pluginData.rangesButtons.hasOwnProperty(pluginData.rangesCustomLabel)
        ) {
          pluginData.rangesButtons[pluginData.rangesCustomLabel].addClass('active');
          $(fp.calendarContainer).removeClass('flatpickr-predefined-ranges-only');
        } else if (pluginData.rangesOnly) {
          $(fp.calendarContainer).addClass('flatpickr-predefined-ranges-only');
        }
      };

      return {
        /**
         * Loop the ranges and add buttons for each range which a click handler to set the date.
         * Also adds a custom range button if the rangesAllowCustom option is set to true.
         */
        onReady(selectedDates) {
          for (const [label, range] of Object.entries(pluginData.ranges)) {
            addRangeButton(label)
                .on('click', function () {
                  let rangeZero = moment(range[0]).format('MMMM, DD YYYY H:mm');
                  let rangeOne = moment(range[1]).format('MMMM, DD YYYY H:mm');
                  $(this).blur();
                  fp.setDate(rangeZero, true);
                  $('#filter-date-to').val(
                      rangeOne
                  )
                  fp.close();

                  reloadPageWithDates(rangeZero, rangeOne);
                })
            ;
          }

          if (pluginData.rangesNav.children().length > 0) {
            if (pluginData.rangesOnly && pluginData.rangesAllowCustom) {
              addRangeButton(pluginData.rangesCustomLabel)
                  .on('click', function () {
                    $(this).blur();
                    pluginData.rangesNav.find('.active').removeClass('active');
                    $(this).addClass('active');
                    $(fp.calendarContainer).removeClass('flatpickr-predefined-ranges-only');
                  })
              ;
            }

            $(fp.calendarContainer).prepend(pluginData.rangesNav);
            $(fp.calendarContainer).addClass('flatpickr-has-predefined-ranges');
            // make sure the right range button is active for the default value
            selectActiveRangeButton(selectedDates);
          }
        },

        /**
         * Make sure the right range button is active when a value is manually entered
         *
         * @param {Array} selectedDates
         */
        onValueUpdate(selectedDates) {
          selectActiveRangeButton(selectedDates);
        }
      };
    };
  }

  window.myFlatpickrPlugins.push(new predefinedRanges());

})(jQuery);

function reloadPageWithDates(from_date, to_date) {
  const parser = new URL(window.location);
  parser.searchParams.set('date_from', from_date);
  parser.searchParams.set('date_to', to_date);
  window.location = parser.href;
}

$(document).on('turbolinks:load', function(){
  // for whatever reason, sometimes flatpickr was taking longer to load.
  // so this has been refactored to check that its not null before trying to use it.
  var date_time_picker = document.getElementsByClassName("date-time-picker");
  var date_from_picker = document.getElementsByClassName("date-from-picker");
  var date_to_picker = document.getElementsByClassName("date-to-picker");
  var date_picker_no_time = document.getElementsByClassName('date-picker-no-time');
  var map_date_from_picker = document.getElementsByClassName('map-date-from-picker');
  var map_date_to_picker = document.getElementsByClassName('map-date-to-picker');
  var filter_date_from_picker = document.getElementsByClassName('filter-date-from-picker');
  var filter_date_to_picker = document.getElementsByClassName('filter-date-to-picker');
  if (date_picker_no_time != null && flatpickr != null) {
    date_picker_no_time.flatpickr({
      dateFormat: "F, d Y H:i",
      defaultHour: 0
    });
  };
  if (date_time_picker != null && flatpickr != null) {
    date_time_picker.flatpickr({
      enableTime: true,
      time_24hr: true,
      dateFormat: "F, d Y H:i",
      defaultHour: 0
    });
  };
  if (date_from_picker != null && flatpickr != null) {
    date_from_picker.flatpickr({
      enableTime: true,
      time_24hr: true,
      dateFormat: "F, d Y H:i",
      defaultHour: 0
    });
  };
  if (date_to_picker != null && flatpickr != null) {
    date_to_picker.flatpickr({
      enableTime: true,
      time_24hr: true,
      dateFormat: "F, d Y H:i",
      defaultHour: 23,
      defaultMinute: 59
    });
  };
  if (map_date_from_picker != null && flatpickr != null) {
    map_date_from_picker.flatpickr({
      enableTime: true,
      time_24hr: true,
      dateFormat: "F, d Y H:i",
      defaultHour: 0,
      position: 'left'
    });
  };
  if (map_date_to_picker != null && flatpickr != null) {
    map_date_to_picker.flatpickr({
      enableTime: true,
      time_24hr: true,
      dateFormat: "F, d Y H:i",
      defaultHour: 23,
      defaultMinute: 59,
      position: 'left'
    });
  };
  if (filter_date_from_picker != null && flatpickr != null) {
    filter_date_from_picker.flatpickr({
      enableTime: true,
      time_24hr: true,
      dateFormat: "F, d Y H:i",
      defaultHour: 0,
      position: 'below',
      plugins: myFlatpickrPlugins,
      ranges: {
        'Today': [moment().startOf('day').toDate(), new Date()],
        'Last Month': [
          moment().subtract(1, 'month').startOf('month').startOf('day').toDate(),
          moment().subtract(1, 'month').endOf('month').endOf('day').toDate()
        ],
        'Last Week': [
          moment().subtract(1, 'week').startOf('isoWeek').startOf('day').toDate(),
          moment().subtract(1, 'week').endOf('isoWeek').endOf('day').toDate()
        ],
        'This Week': [
          moment().startOf('isoWeek').startOf('day').toDate(),
          moment().endOf('isoWeek').endOf('day').toDate()
        ],
        'Next Week': [
          moment().add(1, 'week').startOf('isoWeek').startOf('day').toDate(),
          moment().add(1, 'week').endOf('isoWeek').endOf('day').toDate()
        ],
        'Next Month': [
          moment().add(1, 'month').startOf('month').startOf('day').toDate(),
          moment().add(1, 'month').endOf('month').endOf('day').toDate()
        ]
      },
      rangesOnly: false, // only show the ranges menu unless the custom range button is selected
      rangesAllowCustom: true, // adds a Custom Range button to show the calendar
      rangesCustomLabel: 'Custom Range' // customize the label for the custom range button
    });
    filter_date_to_picker.flatpickr({
      enableTime: true,
      time_24hr: true,
      dateFormat: "F, d Y H:i",
      defaultHour: 23,
      defaultMinute: 59,
      position: 'below',
      "plugins": [new confirmDatePlugin({
        confirmIcon: "<i class='fa fa-check'></i>", // your icon's html, if you wish to override
        confirmText: "Confirm ",
        showAlways: false
      }
    )]
    });
  }
  let planning_date_from = document.getElementById('popout-date-from');
  let planning_date_to = document.getElementById('popout-date-to');
  if (planning_date_from != null && flatpickr != null) {
    planning_date_from.flatpickr({
      enableTime: false,
      dateFormat: 'Y-m-d'
    });
    planning_date_to.flatpickr({
      enableTime: false,
      dateFormat: 'Y-m-d'
    })
  }

  $(".flatpickr-confirm ").on("click", function() {
    let rangeZero = moment($("#filter-date-from").val()).format('MMMM, DD YYYY H:mm');
    let rangeOne = moment($("#filter-date-to").val()).format('MMMM, DD YYYY H:mm');
    reloadPageWithDates(rangeZero, rangeOne);
  })
});
