// mapboxManagementToken has specific permissions for the uploads API
const Config = {
  mapboxAccessToken: 'pk.eyJ1Ijoic3JvYmVydHM5NSIsImEiOiJja2ZkcDg4cTgwMG5mMzBwYW9xdjNxMDZyIn0.B0vkX7Jap9CQPqs38PPGPw',
  mapboxManagementToken: 'sk.eyJ1Ijoic3JvYmVydHM5NSIsImEiOiJja2tncTZ2a2wxNXE4MnZtbjQ2ZHh2M3pkIn0.U6cbojFtqlwqONDlyuoWHg',
  apiUrl: "/api/v1/",
  apiKey: "502913cc4dc566442b50ac13c5a0805a06c3554dbaa9b129daba344b8d5b6ef6b0605dd1a9bf0f7a01aba3a0225befed0e31079db33dae9b502417c01d545b68",
  cloudrfApiKey: "43166-39f06cf8187e8b39486195b53788a7f65eb179c1"
}

const ProductRanges = {
  HUB_ID_LOWER: 0,
  HUB_ID_UPPER: 14999,
  MACHINE_TAG_BOX_ID_LOWER: 15000,
  MACHINE_TAG_BOX_ID_UPPER: 29999,
  CONNECT_ID_LOWER: 30000,
  CONNECT_ID_UPPER: 48999,
  EDGE_SERVER_ID_LOWER: 49900,
  EDGE_SERVER_ID_UPPER: 49999,
  POWER_ID_LOWER: 50000,
  POWER_ID_UPPER: 65599,
  TEAM_TAG_ID_LOWER: 65600,
  TEAM_TAG_ID_UPPER: 1999999,
  HAZARD_TAG_ID_LOWER: 2000000,
  HAZARD_TAG_ID_UPPER: 3999999,
  MACHINE_TAG_ID_LOWER: 4000000,
  MACHINE_TAG_ID_UPPER: 5999999,
  PROXIMITY_ID_LOWER: 10000000,
  PROXIMITY_ID_UPPER: 16777215
}


export default Config;