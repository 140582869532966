$(document).on('turbolinks:load', () => {

    if($('#personnel-role-dropdown').length > 0) {
        $('#personnel-role-dropdown').selectize({
            plugins: ['remove_button']
        });
    }
    if ($('#competency-select').length > 0 ) {
        $('#competency-select').selectize({
            plugins: ['remove_button']
        });
    }
    if ($('#competencies_select').length > 0 ) {
        $('#competencies_select').selectize({
            plugins: ['remove_button']
        });
    }
    if ($('.personnel-competencies-select').length > 0 ) {
        $('.personnel-competencies-select').selectize({
            plugins: ['remove_button']
        });
    }
    if ($('.geofence-green-group-select').length > 0 ) {
        $('.geofence-green-group-select').selectize({
            plugins: ['remove_button']
        });
    }
    if ($('.geofence-amber-group-select').length > 0 ) {
        $('.geofence-amber-group-select').selectize({
            plugins: ['remove_button']
        });
    }
    if ($('.geofence-red-group-select').length > 0 ) {
        $('.geofence-red-group-select').selectize({
            plugins: ['remove_button']
        });
    }

    if ($('.geofence-dropdown-on-zone-data').length > 0 ) {
        $('.geofence-dropdown-on-zone-data').selectize({
            plugins: ['remove_button']
        });
    }

    if ($('.user-dropdown-on-zone-data').length > 0 ) {
        $('.user-dropdown-on-zone-data').selectize({
            plugins: ['remove_button']
        });
    }

    if ($('#user_select').length > 0 ) {
        $('#user_select').selectize({
            plugins: ['remove_button']
        });
    }
    if ($(".workers-select").length > 0) {
        $(".workers-select").selectize({
            plugins: ['remove_button']
        });
    }
    if ($('#machines_select').length > 0 ) {
        $('#machines_select').selectize({
            plugins: ['remove_button']
        });
    }
    if ($('#device_select').length > 0 ) {
        $('#device_select').selectize({
            plugins: ['remove_button']
        });
    }
    if ($('#event_select').length > 0){
        $('#event_select').selectize({
            plugins: ['remove_button']
        });
    }
    if ($('#geofence_select').length > 0 ) {
        $('#geofence_select').selectize({
            plugins: ['remove_button']
        });
    }
    if ($('#machine_zones_select').length > 0 ) {
        $('#machine_zones_select').selectize({
            plugins: ['remove_button']
        });
    }
    if ($('#machine_select').length > 0 ) {
        $('#machine_select').selectize({
            plugins: ['remove_button']
        });
    }
    if ($('#group-user-select').length > 0 ) {
        $('#group-user-select').selectize({
            plugins: ['remove_button']
        });
        $('#group-green-geofence-select').selectize({
            plugins: ['remove_button']
        });
        $('#group-amber-geofence-select').selectize({
            plugins: ['remove_button']
        });
        $('#group-red-geofence-select').selectize({
            plugins: ['remove_button']
        });
    }

    if ($('#proximity_sensors_select').length > 0 ) {
        $('#proximity_sensors').selectize({
            plugins: ['remove_button']
        });
    }
    if ($('#tunnels_select').length > 0) {
        $('#tunnels').selectize({
           plugins: ['remove_button']
        });
        $('#tunnel_tags').selectize({
            plugins: ['remove_button']
        });
    }
});