import Config from "./config/constants";

$(document).on('turbolinks:load', () => {
    if ($('#tunnel-tag-form').length > 0) {
        let $companySelect = $('#tunnel_tag_control_company_id');
        let $siteSelect = $('#tunnel_tag_control_site_id');
        let siteSelector = document.getElementById('tunnel_tag_control_site_id');
        let tunnelSelector = $('#tunnels');
        listenForCompanySelect($companySelect, siteSelector);
        listenForSiteSelect($siteSelect, tunnelSelector);
    }
});

const listenForCompanySelect = ($companySelect, siteSelector) => {
    $companySelect.click(function() {
        let companyId = $('#tunnel_tag_control_company_id').val();
        if (companyId) {
            $.ajax({
                type: 'GET',
                url: `${Config.apiUrl}companies/get_sites_for_company?apikey=${Config.apiKey}&company_id=${companyId}`,
                dataType: "json",
                success: data => {
                    siteSelector.textContent = '';
                    // Add a 'None' option first
                    let opt = document.createElement('option');
                    opt.value = "None";
                    opt.innerHTML = "None";
                    siteSelector.appendChild(opt);

                    // Add the sites as options
                    $.each(data.sites, (index, site) => {
                        let opt = document.createElement('option');
                        opt.value = site["id"];
                        opt.innerHTML = site["name"];
                        siteSelector.appendChild(opt);
                        // This is needed to automatically update the latlongs to those of the first site
                        siteSelector.dispatchEvent(new Event('change'));
                    });
                },
                error: data => {
                    alert("Error trying to retrieve site data. Please refresh the page and try again.");
                }
            });
        }
        else {
            // Remove all options from site selector and replace with 'None' option
            siteSelector.textContent = '';
            let opt = document.createElement('option');
            opt.value = "None";
            opt.innerHTML = "None";
            siteSelector.appendChild(opt);
        }
    });
}

const listenForSiteSelect = ($siteSelect, tunnelSelector) => {
    $siteSelect.click(function () {
        let siteId = $('#tunnel_tag_control_site_id').val();
        if (siteId) {
            $.ajax({
                type: 'GET',
                url: `${Config.apiUrl}map_sites/get_tunnels_for_site?apikey=${Config.apiKey}&site_id=${siteId}`,
                dataType: "json",
                success: data => {
                    let tunnel_list = []
                    $.each(data.tunnels, (index, tunnel) => {
                        tunnel_list.push({
                            text: tunnel.name,
                            value: tunnel.id
                        })
                    });

                    var selectize = tunnelSelector[0].selectize;
                    selectize.clear();
                    selectize.clearOptions();
                    selectize.load(function(callback) {
                        callback(tunnel_list);
                    });
                },
                error: data => {
                    alert("Error trying to retrieve tunnel data. Please refresh the page and try again.");
                }
            });
        }
    });
}
