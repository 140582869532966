import Config from "./config/constants";

$(document).on('turbolinks:load', function(){
  // handle populating the modal for further Connect information
  handleModal();
});
function handleModal() {
  var modal = $(".modal");

  $(".connect-modal-activator").click(function (e) {
    let connect_id = $(this).data("value")
    $.ajax({
      type: 'GET',
      url: `${Config.apiUrl}connects/get_connect_solar_data?apikey=${Config.apiKey}&connect_id=${connect_id}`,
      dataType: "json",
      success: data => {
        // process the data for the modal, null check each attribute and append to the HTML element it relates to
        let panel_power = data.panel_power == null ? "N/A" : data.panel_power + "W";
        let battery_voltage = data.battery_voltage == null ? "N/A" : data.battery_voltage + "mV";
        let load_output_state = data.load_output_state == null ? "N/A" : data.load_output_state;
        let load_current = data.load_current == null ? "N/A" : data.load_current + "mA";
        let off_reason = data.off_reason == null ? "N/A" : data.off_reason;
        let yield_today = data.yield_today == null ? "N/A" : data.yield_today + "kWh";
        let yield_yesterday = data.yield_yesterday == null ? "N/A" : data.yield_yesterday + "kWh";
        let max_power_today = data.max_power_today == null ? "N/A" : data.max_power_today + "W";
        let max_power_yesterday = data.max_power_yesterday == null ? "N/A" : data.max_power_yesterday + "W";
        let error_code = data.error_code == null ? "N/A" : data.error_code;
        let state_of_operation = data.state_of_operation == null ? "N/A" : data.state_of_operation;
        $(".modal-card-title").empty().append("Connect Id: " + data.connect_id);
        $(".panel-power-text").empty().append(panel_power);
        $(".battery-voltage-text").empty().append(battery_voltage);
        $(".load-output-state-text").empty().append(load_output_state);
        $(".load-current-text").empty().append(load_current);
        $(".off-reason-text").empty().append(off_reason);
        $(".yield-today-text").empty().append(yield_today);
        $(".yield-yesterday-text").empty().append(yield_yesterday);
        $(".max-power-today-text").empty().append(max_power_today);
        $(".nax-power-yesterday-text").empty().append(max_power_yesterday);
        $(".error-code-text").empty().append(error_code);
        $(".state-of-operation-text").empty().append(state_of_operation);

        modal.addClass("is-active");
      },
      error: data => {
        alert("Error trying to retrieve connect data. Please refresh the page and try again.");
      }
    });
  });
  $(".modal-background, #modal-close").click(function (e) {
    //close modal
    modal.removeClass("is-active");
  });
}
