import Slideout from 'slideout';

document.addEventListener("turbolinks:load", () => {
  if ($('.toggle-button').length) {
    initiateSlideout();
  }
});

let slideoutObject;

const initiateSlideout = () => {
  const slideout = new Slideout({
    'panel': document.getElementById('panel'),
    'menu': document.getElementById('menu'),
    'tolerance': 70,
    'touch': false
  });

  // Toggle button
  document.querySelector('.toggle-button').addEventListener('click', () => slideoutToggle(slideout));

  slideoutObject = slideout;
}

function slideoutToggle(slideout){
  let topNav = $('.top-navigation');
  if(topNav.css('left') === '0px'){
    topNav.animate({ 'left': '256px' }, 300);
  }else{
    topNav.animate({ 'left': '0px' }, 300);
  }

  slideout.toggle();
}

export { slideoutObject };