import Config from "./config/constants";

$(document).on('turbolinks:load', () => {
    const errorType = $("#error-tag");
    if (errorType.length) {
        listenForSubmitClick();
        removeSideMenu();
    }
});

const listenForSubmitClick = () => {
    $("#submit-button").click(function() {
        let description = $("#error-description").val();
        let errorCode = $("#error-tag").val();
        let userId = $('#user-id').val();

        $.ajax({
            type: 'POST',
            url: `${Config.apiUrl}user_errors/submit_error_report?apikey=${Config.apiKey}&user_id=${userId}&error_code=${errorCode}&comments=${description}`,
            success: data => {
            },
            error: data => {

            }
        });
        }
    )
}

// The side menu can sometimes bug out when an error is displayed
const removeSideMenu = () => {
    $('#menu').remove();
    $('#menu-toggle').remove();
}