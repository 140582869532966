document.addEventListener("turbolinks:load", function() {
  if ($("#alerts-container").length > 0){
    showOrHideGeofenceDropdown();
    listenToUserRoleSelection();
    listenToEventTypeSelection();
  }
})

function listenToUserRoleSelection() {
  $("#non_site_user").change(function() {
    var selectedText = $(this).find(':selected').text();
    var $userSelect = $("#user-select");
    var $nonSiteUserForm = $("#non-site-user-form");

    showOrHidePlatformAlertsCheckbox();
    if (selectedText == "Platform User") {
      showElement($userSelect);
      hideElement($nonSiteUserForm);
    } else if (selectedText == "Non Platform User") {
      showElement($nonSiteUserForm);
      hideElement($userSelect);
    }
  });
}

function listenToEventTypeSelection() {
  $("#event_type_id").change(function() {
    showOrHideGeofenceDropdown();
    showOrHidePlatformAlertsCheckbox();
  });
}

function hideElement(element) {
  if (!element.hasClass("is-hidden")) {
    element.addClass("is-hidden");
  }
}

function showElement(element) {
  element.removeClass("is-hidden");
}

function showOrHideGeofenceDropdown() {
  var $selectedText = $("#event_type_id").find(':selected').text();
  var $geofenceSelect = $("#alerts-geofence-select");

  if (eventInvolvesZones($selectedText)) {
    showElement($geofenceSelect);
  } else {
    hideElement($geofenceSelect);
  }
}

function eventInvolvesZones(event){
  return event === "TeamTag Zone Entry"
      || event === "TeamTag Zone Exit"
      || event === "MachineTag Zone Entry"
      || event === "MachineTag Zone Exit"
      || event === "Extended Breach"
      || event === "Repeated Breaches"
}

function showOrHidePlatformAlertsCheckbox() {
  var $selectedText = $("#event_type_id").find(':selected').text();
  var $selectedSiteUserText = $("#non_site_user").find(':selected').text();
  var $platformCheckbox = $("#platform_active_checkbox");

  if ($selectedText == "SOS" && $selectedSiteUserText == "Platform User") {
    showElement($platformCheckbox);
  } else {
    hideElement($platformCheckbox);
  }
}