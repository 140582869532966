$(document).on('turbolinks:load', () => {
    if(document.getElementById('add-point-button')){
        document.getElementById('add-point-button').onclick = () => {
            let container = document.getElementById('manual-zone-coordinate-section');
            addLatLongColumn(container);
        }
    }

    if(document.getElementById('edit-add-point-button')){
        document.getElementById('edit-add-point-button').onclick = () => {
            let container = document.getElementById('geofence-edit-points');
            addLatLongColumn(container);
        }
    }

    function addLatLongColumn(container) {
        let columns_block = document.createElement('div');
        columns_block.setAttribute('class', 'columns');

        columns_block.innerHTML = `
              <div class="column is-half">
                <div class="field is-inline is-half-desktop has-margin-bottom-20">
                  <label class="label" for="latitude">Latitude</label>
                  <div class="control">
                    <input type="number" name="latitude[]" id="latitude_" step="any" min="-90" max="90" class="input">
                  </div>
                </div>
              </div>
              
              <div class="column is-half">
                <div class="field is-inline is-half-desktop has-margin-bottom-20">
                  <label class="label" for="longitude">Longitude</label>
                  <div class="control">
                    <input type="number" name="longitude[]" id="longitude_" step="any" min="-180" max="180" class="input">
                  </div>
                </div>
              </div>
              `

        container.appendChild(columns_block);
    }
});