import MapConfig from '../config/map_config';
import _ from 'lodash';
import Config from "../config/constants";
import {
  geofenceAjaxCall,
  imageDataAjaxCall,
  fitMapToBounds,
  tunnelAjaxCall,
  perimeterAjaxCall, machineZoneAjaxCall
} from './map_ajax';
import {
  addPolygonsMouseOver
} from './map'
import mapboxgl from 'mapbox-gl';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as turf from '@turf/turf'
import {getInitialSite, siteId} from "./site_management";


document.addEventListener("turbolinks:load", () => {
  if ($('#configure_map').length) {
    let isTunnel = null;
    if ($('#is_tunnel').length) {
      isTunnel = true;
    } else {
      isTunnel = false;
    }
    $.when(getInitialSite()).done(() => {
      if (isTunnel) {
        initiateTunnelConfigureMap();

      } else {
        let save_machine_zone_button = $(".save-machine-zone-button");
        if (save_machine_zone_button.length){
          initiateZoneConfigureMap(true);
        }else{
          initiateZoneConfigureMap(false);
        }
      }
    })
    if (isTunnel) {
      saveTunnel();
    } else {
      saveGeofence();
    }
  }
})

// Save the geofence when clicking the Save button
const saveGeofence = () => {
  const save_geofence_button = $('.geofence-save-button');
  const geofenceId = document.getElementById('geofence_id').value;
  const siteId = document.getElementById("site_id").value;

  // Change the url of the return button depending on zone type
  if ($('#zone_type').val() === "productivity") {
    $('.return-button').attr('href', '/zones/productivity_zones');
  }

  save_geofence_button.click((e) => {
    let array_of_long_lats = document.getElementById('geofence_array').value;
    let url = window.location.href;

    if (array_of_long_lats) {
      url = url.replace("configure", "save_geofence");
      $.ajax({
        type: 'POST',
        url: url,
        data: { 'geofence_array' : array_of_long_lats, 'site_id' : siteId, geofence_id: geofenceId },
        dataType: "json",
        success: function(data) {
        },
      });
      $('.back-button ').hide();
      $('.return-button').show();
    }
    else {
      alert("Cannot save zone. A zone needs a shape.");
    }
  })
}

const saveTunnel = () => {
  const $saveButton = $('.geofence-save-button');
  const tunnelId = document.getElementById('tunnel_id').value;
  const siteId = document.getElementById("site_id").value;
  $saveButton.click((e) => {
    let array_of_long_lats = document.getElementById('geofence_array').value;
    let url = window.location.href;

    if (array_of_long_lats) {
      url = url.replace("configure", "save_geofence");
      $.ajax({
        type: 'POST',
        url: url,
        data: { 'geofence_array' : array_of_long_lats, 'site_id' : siteId, 'tunnel_id': tunnelId },
        dataType: "json",
        success: function(data) {
        },
      });
      $('.back-button ').hide();
      $('.return-button').show();
    }
    else {
      alert("Cannot save zone. A zone needs a shape.");
    }
  })
}

// need the map object to set the AJAX calls
// on the window method of setInterval
let configureMapObject;

const initiateTunnelConfigureMap = () => {
  const siteId = document.getElementById("site_id").value;
  const siteCoordinates = document.getElementById("site_coordinates").value;
  const tunnelId = document.getElementById('tunnel_id').value;
  const tunnelIds = document.getElementById('tunnel_ids').value;
  mapboxgl.accessToken = Config.mapboxAccessToken;
  const map = new mapboxgl.Map({
    container: 'configure_map',
    style: MapConfig.darkStyle,
    center: siteCoordinates.split(" "),
    zoom: MapConfig.startingZoom,
    minZoom: MapConfig.zoomOutMax,
    maxZoom: MapConfig.zoomInMax
  });
  fitMapToBounds(map, siteId, siteCoordinates);
  let controls = {
    polygon: true,
    trash: true
  }
  const draw = new MapboxDraw({
    ...MapboxDraw.modes,
    displayControlsDefault: false,
    // Select which mapbox-gl-draw control buttons to add to the map.
    controls: controls
  });
  map.addControl(draw);
  map.on('draw.create', updateArea);
  map.on('draw.delete', updateArea);
  map.on('draw.update', updateArea);
  map.addControl(new mapboxgl.FullscreenControl());
  map.addControl(new mapboxgl.NavigationControl());
  map.addControl(new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true
      })
  );
  // Called when the user completes the polygon shape on the map
  function updateArea(e) {
    const data = draw.getAll();
    if (data.features.length > 0) {
      let array_of_long_lats = data.features[0].geometry.coordinates[0];

      // Team tags can only hold a maximum of 50 points per geofence, so don't allow any fences larger than that to be created.
      if (array_of_long_lats.length > 50){
        window.alert("A zone can have a maximum of 50 points.");
        location.reload();
      } else{
        // Save the shape in the geofence_array element to use when saving
        document.getElementById('geofence_array').value = array_of_long_lats
      }
    }
  }
  //**************************************************
  //**************************************************


  //**************************************************
  //**************************************************
  // MAP EVENT LISTENERS

  map.on('load', () => {
    addPolygonsMouseOver(map, 'polygons');
    var layerList = document.getElementById('style-menu');
    var inputs = layerList.getElementsByTagName('input');
    imageDataAjaxCall(map, null, null, null, false, true, true, false, null, siteId, null, false, false, true)
    tunnelAjaxCall(map, null);

    var layerList = document.getElementById('style-menu');
    var inputs = layerList.getElementsByTagName('input');
    for (var i = 0; i < inputs.length; i++) {
      inputs[i].onclick = switchLayer;
    }

    function switchLayer(layer) {
      var layerId = layer.target.id;
      if(layerId == "default"){
        map.setStyle(MapConfig.darkStyle);
        imageDataAjaxCall(map, null, null, null, false, true, true, false, null, siteId, null, false, false, true)
        tunnelAjaxCall(map, null);
      }else{
        map.setStyle('mapbox://styles/mapbox/' + layerId);
        imageDataAjaxCall(map, null, null, null, false, true, true, false, null, siteId, null, false, false, true)
        tunnelAjaxCall(map, null);
      }
    }
  });
  //**************************************************
  //**************************************************
  configureMapObject = map;
}

const initiateZoneConfigureMap = (is_machine_map) => {
  const siteId = document.getElementById("site_id").value;
  const siteCoordinates = document.getElementById("site_coordinates").value;
  const isGeofenceActive = document.getElementById('is_geofence_active').value;
  const geofenceId = document.getElementById('geofence_id').value;
  const geofenceIds = document.getElementById('geofence_ids').value;

  mapboxgl.accessToken = Config.mapboxAccessToken;
  const map = new mapboxgl.Map({
    container: 'configure_map',
    style: MapConfig.regularStyle,
    center: siteCoordinates.split(" "),
    zoom: MapConfig.startingZoom,
    minZoom: MapConfig.zoomOutMax,
    maxZoom: MapConfig.zoomInMax
  });

  fitMapToBounds(map, siteId, siteCoordinates);

  // Change controls depending on if it is a walkway or a hazard zone
  var controls = {
    polygon: true,
    trash: true
  }

  const draw = new MapboxDraw({
    ...MapboxDraw.modes,
    displayControlsDefault: false,
    // Select which mapbox-gl-draw control buttons to add to the map.
    controls: controls,

    // --------- Style for the drawing tool to make it more visible on the map -----------
    styles: [
      // ACTIVE (being drawn)
      // line stroke
      {
        "id": "gl-draw-line",
        "type": "line",
        "filter": ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
        "layout": {
          "line-cap": "round",
          "line-join": "round"
        },
        "paint": {
          "line-color": "#1c1c1c",
          "line-dasharray": [0.2, 2],
          "line-width": 2
        }
      },
      // polygon fill
      {
        "id": "gl-draw-polygon-fill",
        "type": "fill",
        "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
        "paint": {
          "fill-color": "#1c1c1c",
          "fill-outline-color": "#1c1c1c",
          "fill-opacity": 0.5
        }
      },
      // polygon mid points
      {
        'id': 'gl-draw-polygon-midpoint',
        'type': 'circle',
        'filter': ['all',
          ['==', '$type', 'Point'],
          ['==', 'meta', 'midpoint']],
        'paint': {
          'circle-radius': 3,
          'circle-color': '#fbb03b'
        }
      },
      // polygon outline stroke
      // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
      {
        "id": "gl-draw-polygon-stroke-active",
        "type": "line",
        "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
        "layout": {
          "line-cap": "round",
          "line-join": "round"
        },
        "paint": {
          "line-color": "#1c1c1c",
          "line-dasharray": [0.2, 2],
          "line-width": 2
        }
      },
      // vertex point halos
      {
        "id": "gl-draw-polygon-and-line-vertex-halo-active",
        "type": "circle",
        "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
        "paint": {
          "circle-radius": 5,
          "circle-color": "#FFF"
        }
      },
      // vertex points
      {
        "id": "gl-draw-polygon-and-line-vertex-active",
        "type": "circle",
        "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
        "paint": {
          "circle-radius": 3,
          "circle-color": "#1c1c1c",
        }
      },
    ]
    // --------- End Styling -----------
  });


  // Can only configure active geofences, inactive ones can only be viewed
  if (isGeofenceActive == 'true') {
    map.addControl(draw);
  }
  map.on('draw.create', updateArea);
  map.on('draw.delete', updateArea);
  map.on('draw.update', updateArea);

  map.addControl(new mapboxgl.FullscreenControl());
  map.addControl(new mapboxgl.NavigationControl());
  map.addControl(new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true
      })
  );
  // Called when the user completes the polygon shape on the map
  function updateArea(e) {
    const data = draw.getAll();
    if (data.features.length > 0) {
      let array_of_long_lats = data.features[0].geometry.coordinates[0];

      // Team tags can only hold a maximum of 50 points per geofence, so don't allow any fences larger than that to be created.
      if (array_of_long_lats.length > 50){
        window.alert("A zone can have a maximum of 50 points.");
        location.reload();
      } else{
        // Save the shape in the geofence_array element to use when saving
        document.getElementById('geofence_array').value = array_of_long_lats
      }
    }
  }
  //**************************************************
  //**************************************************


  //**************************************************
  //**************************************************
  // MAP EVENT LISTENERS
  map.on('mousemove', (e) => {
    document.getElementById('latlong-text').innerText =
        // `e.lngLat` is the longitude, latitude geographical position of the event.
        Number(e.lngLat.lat).toFixed(4) + ', ' + Number(e.lngLat.lng).toFixed(4)
  });

  map.on('load', async () => {
    addPolygonsMouseOver(map, 'polygons');
    addPolygonsMouseOver(map, 'machine_zones');
    var layerList = document.getElementById('style-menu');
    var inputs = layerList.getElementsByTagName('input');
    const zoneType = document.getElementById('zone_type').value;

    async function switchLayer(layer) {
      var layerId = layer.target.id;
      if (layerId == "default") {
        map.setStyle('mapbox://styles/sroberts95/ckjssbjs10v9519ozm81mugy9');
        await imageDataAjaxCall(map, geofenceIds, null, null, false, false, true, true, zoneType, siteId, null, false, true, false);
        perimeterAjaxCall(map, null, siteId, true, false);
        if (zoneType == 'machine') {
          await machineZoneAjaxCall(map, null, geofenceIds, false, false, false, null, null, null);
        } else {
          await geofenceAjaxCall(map, geofenceIds, null, null, true, false, false, true, zoneType, false, geofenceId, false, false, null, null, null);
        }

      } else {
        map.setStyle('mapbox://styles/mapbox/' + layerId);
        await imageDataAjaxCall(map, geofenceIds, null, null, false, false, true, true, zoneType, siteId, null, false, true, false);
        perimeterAjaxCall(map, null, siteId, true, false);
        if (zoneType == 'machine') {
          await machineZoneAjaxCall(map, null, geofenceIds, false, false, false, null, null, null);
        } else {
          await geofenceAjaxCall(map, geofenceIds, null, null, true, false, false, true, zoneType, false, geofenceId, false, false, null, null, null);
        }
      }
    }

    for (var i = 0; i < inputs.length; i++) {
      inputs[i].onclick = switchLayer;
    }
    await imageDataAjaxCall(map, geofenceIds, null, null, false, false, false, true, zoneType, siteId, null, false, true, false);
    perimeterAjaxCall(map, null, siteId, true, false);
    if (zoneType == 'machine') {
      await machineZoneAjaxCall(map, null, geofenceIds, false, false, false, null, null, null);
    } else {
      await geofenceAjaxCall(map, geofenceIds, null, null, true, false, false, true, zoneType, false, geofenceId, false, false, null, null, null);
    }
  });
  //**************************************************
  //**************************************************
  configureMapObject = map;
}

export { configureMapObject };
