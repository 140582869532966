import moment from 'moment';

moment.locale('en-gb', {
  calendar : {
    lastDay : '[Yesterday at] LTS',
    sameDay : '[Today at] LTS',
    nextDay : '[Tomorrow at] LTS',
    lastWeek : '[last] dddd [at] LTS',
    nextWeek : 'dddd [at] LTS',
    sameElse : 'll [at] LTS'
  }
});