import ProductRanges from "./config/constants";
import Config from "./config/constants";

$(document).on('turbolinks:load', () => {
    var orderForm = document.getElementById('order-form');
    if (orderForm !== null) {
        // Hide the product sub type selector as it's only needed for MTs, connects and power boxes
        const productSubTypeSelector = document.getElementById('product_sub_type_field');
        $(productSubTypeSelector).hide();
        // Hide the SIM text box as it's only needed for hubs, connects, mtc's and powerbox+'s
        const simNumberSelector = document.getElementById('sim_number_field');
        $(simNumberSelector).hide();

        const qrTextBox = document.getElementById('qr-code-text-box');
        qrTextBox.focus();
        qrTextBox.select();
        const productTypeSelector = document.getElementById('order_product_type');
        // Listen for if the product type has been changed
        productTypeSelector.addEventListener('change', function() { listenForProductType(productTypeSelector, simNumberSelector, productSubTypeSelector) });
        // Listen for if the product sub-type has been changed
        productSubTypeSelector.addEventListener('change', function() { listenForProductSubType(productTypeSelector, simNumberSelector) });
        // Listen for if the qr code data has been input
        qrTextBox.addEventListener('change', function() { listenForQRRead(qrTextBox) });
        showProductFields();
    }
});

function listenForProductSubType(productTypeSelector, simNumberSelector) {
    let productSubTypeSelector = document.getElementById('product_sub_type_select');
    let productValue = productTypeSelector.value.replaceAll("[", "").replaceAll("]", "").replaceAll("\"", "").split(",");
    // Only check the sub type's value if the main type is a Power Box
    if (productValue[0] === "power_box") {
        // If the subtype is a powerbox+ we need to show the SIM number field
        if (productSubTypeSelector.value === "Powerbox+") {
            $(simNumberSelector).show();
        }
        else {
            $(simNumberSelector).hide();
        }
    }
}

function listenForQRRead(qrTextBox) {
    // Split the data into its component parts
    let data = qrTextBox.value.split('¬');
    if (isValidQRCode(data)){
        let productNumber = data[0].substring(3);
        let productNumberTextBox = document.getElementById('order_product_product_id');
        productNumberTextBox.value = parseInt(productNumber, 10);
        let productType = data[2].substring(4, 8);
        // Set the product type
        setProductType(productType);

        // Cleanup the text box so it can be filled again
        qrTextBox.value = "";

        // Listen for if the form is complete so the user can quickly make orders
        const productTypeSelector = document.getElementById('order_product_type');
        const productNumberSelector = document.getElementById('order_product_product_id');
        if (productTypeSelector.value !== "" && productNumberSelector.value !== "") {
            let submitButton = document.getElementById('submit-button');
            submitButton.click();
        }
    }
    // If the qr code is invalid and the text box is not blank...
    else if (!isValidQRCode(data) && data.length !== 1) {
        alert("That QR Code is invalid. Please try scanning again.");
    }
}

function setProductType(productCode) {
    let productTypeTextBox = document.getElementById('order_product_type');
    let productSubType = document.getElementById('product_sub_type_select');        // The select field
    let productSubTypeField = document.getElementById('product_sub_type_field');    // The parent div
    let simNumberSelector = document.getElementById('sim_number_field');

    switch (productCode) {
        case "W100":                                // Team Tags
            $(productTypeTextBox).val("team_tag");
            break;
        case "W200":                                // Hazard Tags
            $(productTypeTextBox).val("hazard_tag");
        case "E100":                                // Proximities
            $(productTypeTextBox).val("proximity");
            break;
        case "P100":                                // Power Boxes
            $(productTypeTextBox).val("power_box");
            $(productSubTypeField).show();
            setProductSubTypes(["Powerbox", "UPS", "Powerbox+"]);
            productSubType.value = "Powerbox";
            break;
        case "P400":
            $(productTypeTextBox).val("power_box");
            $(productSubTypeField).show();
            setProductSubTypes(["Powerbox", "UPS", "Powerbox+"]);
            productSubType.value = "UPS";
            break;
        case "P500":
            $(productTypeTextBox).val("power_box");
            $(productSubTypeField).show();
            $(simNumberSelector).show();
            setProductSubTypes(["Powerbox", "UPS", "Powerbox+"]);
            productSubType.value = "Powerbox+";
            break;
        case "N200":                                // Connects
            $(productSubTypeField).show();
            setProductSubTypes(["240VAC", "POE", "DC", "110VAC"]);
            productSubType.value = "240VAC";
            $(productTypeTextBox).val("connect");
            break;
        case "N201":
            $(productSubTypeField).show();
            setProductSubTypes(["POE", "DC", "110VAC", "240VAC"]);
            productSubType.value = "POE";
            $(productTypeTextBox).val("connect");
            break;
        case "N202":
            $(productSubTypeField).show();
            setProductSubTypes(["DC", "110VAC", "240VAC", "POE"]);
            productSubType.value = "DC";
            $(productTypeTextBox).val("connect");
            break;
        case "N203":
            $(productSubTypeField).show();
            setProductSubTypes(["110VAC", "240VAC", "POE", "DC"]);
            productSubType.value = "110VAC";
            $(productTypeTextBox).val("connect");
            break;
        case "N100":                                // Hubs
            $(productTypeTextBox).val("hub");
            $(simNumberSelector).show();
            break;
        case "W300":                                // Machine Tag Controls
            $(productTypeTextBox).val("machine_tag");
            $(productSubTypeField).show();
            $(simNumberSelector).show();
            setProductSubTypes(["Default", "5G"]);
            productSubType.value = "Default";
            break;
        case "W600":
            $(productTypeTextBox).val("machine_tag");
            $(productSubTypeField).show();
            $(simNumberSelector).show();
            setProductSubTypes(["Default", "5G"]);
            productSubType.value = "5G";
            break;
        case "W400":                                // Machine Tag Sensors
            $(productTypeTextBox).val("machine_tag_proximity_sensor");
            break;
        default:
            alert("Product not recognised.");
    }
}

function isValidQRCode(data) {
    let isValid = false;
    let entry1 = data[0];
    let entry2 = data[1];
    let entry3 = data[2];
    let entry4 = data[3];
    // There should be 4 entries
    // Entry 1 is of format SN-XXXXXXXX
    // Entry 2 is of format XXXXX
    // Entry 3 is of format SKU-XXX-XXX-X
    // Entry 4 is of format XX-XXXX
    if (entry1.substring(0, 2) === "SN" && entry1.substring(2, 3) === "-" && entry1.length === 11
        && entry2.length === 5
        && entry3.substring(0, 3) === "SKU" && entry3.substring(3, 4) === "-" && entry3.substring(8, 9) === "-" && entry3.substring(12, 13) === "-" && entry3.length === 14
        && entry4.substring(2, 3) === "-" && entry4.length === 7) {
        isValid = true;
    }
    return isValid;
}

// Need to show SIM Number and/or sub-type selectors for certain products
function listenForProductType(productTypeSelector, simNumberSelector, productSubTypeSelector) {
    let productType = productTypeSelector.value.replaceAll("[", "").replaceAll("]", "").replaceAll("\"", "").split(",")[0];
    switch (productType) {
        // Products that do not have a sim number or sub type
        case "team_tag":
        case "hazard_tag":
        case "machine_tag_proximity_sensor":
        case "proximity":
            $(simNumberSelector).hide();
            $(productSubTypeSelector).hide();
            showProductFields();
            break;
        // Products that have a sim number and/or sub type
        case "machine_tag":
            $(simNumberSelector).show();
            setProductSubTypes(["Default", "5G"]);
            $(productSubTypeSelector).show();
            showProductFields();
            break;
        case "hub":
            $(simNumberSelector).show();
            $(productSubTypeSelector).hide();
            showProductFields("Hub");
            break;
        case "connect":
            $(simNumberSelector).show();
            setProductSubTypes(["240VAC", "POE", "DC", "110VAC"]);
            $(productSubTypeSelector).show();
            showProductFields("Connect");
            break;
        case "power_box":
            $(simNumberSelector).hide();
            setProductSubTypes(["Powerbox", "UPS", "Powerbox+"]);
            $(productSubTypeSelector).show();
            showProductFields();
            break;
    }
}

function setProductSubTypes(options) {
    let productSubTypeSelector = document.getElementById('product_sub_type_select');
    productSubTypeSelector.textContent = '';
    $.each(options, (index, option) => {
        let opt = document.createElement('option');
        opt.value = option;
        opt.innerHTML = option;
        productSubTypeSelector.appendChild(opt);
    });
}

function showProductFields(type) {
    // Hub-only fields
    const allocPriority = $('#alloc_priority_field');
    const loginMin = $('#login_min_field');
    const loginMax = $('#login_max_field');
    const hubFields = [allocPriority, loginMin, loginMax]
    // Shared fields
    const timeslot = $('#timeslot_field');
    const sharedFields = [timeslot];

    // Depending on the type of product selected, show the correct input fields
    if (type === "Hub") {
        hubFields.forEach((element, index) => {
            element.show();
            $(element.children()[1].childNodes[1]).attr('required', true);
        });
        sharedFields.forEach((element, index) => {
            element.show();
            $(element.children()[1].childNodes[1]).attr('required', true);
        });
    }
    else
    {
        hubFields.forEach((element, index) => {
            element.hide();
            $(element.children()[1].childNodes[1]).attr('required', false);
        });
        sharedFields.forEach((element, index) => {
            element.hide();
            $(element.children()[1].childNodes[1]).attr('required', false);
        });
    }
}