import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

$(document).on('turbolinks:load', () => {
    listenForPermitSign();

    if ($('#geofence_select').length > 0) {
        $('#geofence_select').selectize({
            plugins: ['remove_button']
        });
    }
});

document.addEventListener("turbolinks:load", function() {
    $("#capture-permit").click(function() {
        $("#preview-menu").hide();
        $("#permit-pdf-additional-fields").show();
        $("#buttons-to-hide").hide();
        html2canvas(document.getElementById("permit-form-container"), {scrollY: -window.scrollY}).then(function(canvas) {
            const contentDataURL = canvas.toDataURL('image/png')
            var imgWidth = 210;
            var pageHeight = 295;
            var imgHeight = canvas.height * imgWidth / canvas.width;
            var heightLeft = imgHeight;

            var doc = new jsPDF('p', 'mm');
            var position = 0;

            doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);

            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }
            // File name
            let projectName = document.getElementById("permit_project_name").value;
            if (projectName == null || projectName == undefined || projectName == ""){
                projectName = "Permit"
            }
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            today = dd + '-' + mm + '-' + yyyy;
            doc.save( projectName + "-" + today + '.pdf');
        });
        $("#buttons-to-hide").show();
        $("#permit-pdf-additional-fields").hide();
        $("#preview-menu").show();
    })
});

function listenForPermitSign(){
    $("#signature-activator").click(function() {
       $("#permit-signature-section").show();
    });
}