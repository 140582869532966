$(document).on('turbolinks:load', () => {
    const $eventType = $('.site-dash-event-dropdown');
    if ($eventType.length) {
        // Only run when bumps are selected in the site dash event dropdown
        // Prevents 'google' not defined error
        if ($eventType.val() === "Bumps") {
            google.charts.load('current', {packages: ['corechart']});
            google.charts.setOnLoadCallback(drawLineChart);
            google.charts.setOnLoadCallback(drawBarChart);
        }
    }
});

function drawLineChart() {
    // Define the chart
    var data = new google.visualization.DataTable();
    data.addColumn('date', 'time');
    data.addColumn('number', 'minutes');

    // Instantiate and draw the chart
    var chart = new google.visualization.LineChart(document.getElementById('chart-1'));
    var originalData = document.getElementById('historical_collisions').value;
    originalData = cleanupData(originalData);
    // Create times
    var axes = generateAxisData(originalData);
    var minutes = axes[1];
    var dates = axes[0];
    var result = [];
    var i = 0;
    // Convert the data strings into numbers and dates
    while ( minutes[++i] ) {
        result.push( [ new Date(dates[i]), parseInt(minutes[i]) ] );
    }
    data.addRows(result);
    var options = {
        titleTextStyle: {
            fontSize: 22,
            color: '#333333'
        },
        hAxis: {
            title: 'dates',
            textStyle: {
                fontSize: 12
            },
            titleTextStyle: {
                italic: false,
                fontSize: 16
            }
        },
        vAxis: {
            title: 'minutes',
            textStyle: {
                fontSize: 12
            },
            titleTextStyle: {
                italic: false,
                fontSize: 16
            }
        },
        chartArea: {
            // leave room for y-axis labels
            width: '78%'
        },
        title: 'Collision History in Minutes',
        height: '100%',
        width: '100%',
        pointSize: 6,
        curveType: 'function',
        series: [{color: '#98B2E5', visibleInLegend: false}]
    }
    // Only draw the google chart if the date range is one week or more
    var number_of_days_search = document.getElementById('number_of_days_search').value;
    if (number_of_days_search >= 7) {
        // Chartkick looks nicer and has the same functionality for date ranges < 1 week.
        chart.draw(data, options);
    }

    google.visualization.events.addListener(chart, 'select', function() {
        var selection = chart.getSelection();
        if (selection && selection[0]) {
            var link = window.location.href;
            // Remove all chars after the third slash in the url
            var pos = getPosition(link, "/", 3);
            link = link.substr(0, pos);
            link = link.concat("/sites_dashboard");
            // Work out the params to send
            const urlParams = new URLSearchParams(window.location.search)
            var siteID = urlParams.get('site_id');
            // Work out the date selected on the graph
            var date = new Date(dates[selection[0].row]);
            // Add one day to the date because for some reason google charts stores it as one day behind
            date.setDate(date.getDate() + 1);
            // Take away an hour for the same reason
            date.setHours(date.getHours() - 1);
            var date_from = strftime('%B, %d %Y %H:00', date);
            // Have to set UTC hours and add on 23 hours explicitly
            date.setUTCHours(23,59,59,999);
            date.setHours(date.getHours() + 23);
            var date_to = strftime('%B, %d %Y %H:%M', date);
            
            // Link to the data for the whole of the date selected
            link = link.concat('?site_id=' + siteID + "&date_from=" + date_from +
                '&date_to=' + date_to + '&event=Bumps');
            window.open(link);
        }
    })
}

function drawBarChart() {
    // Define the chart to be drawn.
    var data = new google.visualization.DataTable();
    data.addColumn('string', 'User ID');
    data.addColumn('number', 'Time');

    // Instantiate and draw the chart.
    var chart = new google.visualization.BarChart(document.getElementById('chart-2'));
    var originalData = document.getElementById('sorted_user_interactions').value;
    originalData = cleanupData(originalData);
    // Create times
    var axes = generateAxisData(originalData);
    var minutes = axes[1];
    var users = axes[0];
    var result = [];
    var i = 0;
    // Convert the data strings into numbers and dates
    for (let i = 0; i < minutes.length; i++) {
        result.push( [ users[i], parseInt(minutes[i]) ] );
    }
    // Sort the result so the user with the highest minutes is at the top
    result.sort(function(a, b){ return b[1] - a[1]});
    var options = {
        titleTextStyle: {
            fontSize: 22,
            color: '#333333'
        },
        hAxis: {
            title: 'minutes',
            textStyle: {
                fontSize: 12
            },
            titleTextStyle: {
                italic: false,
                fontSize: 16
            }
        },
        vAxis: {
            title: 'users',
            textStyle: {
                fontSize: 12
            },
            titleTextStyle: {
                italic: false,
                fontSize: 16
            }
        },
        chartArea: {
            // leave room for y-axis labels
            width: '78%'
        },
        title: 'Top Interactions in Minutes',
        height: '100%',
        width: '100%',
        series: [{color: '#98B2E5', visibleInLegend: false}]
    };
    data.addRows(result);
    chart.draw(data, options);

    google.visualization.events.addListener(chart, 'select', function() {
        var selection = chart.getSelection();
        if (selection && selection[0]) {
            var link = window.location.href;
            // Remove all chars after the third slash in the url
            var pos = getPosition(link, "/", 3);
            link = link.substr(0, pos);
            link = link.concat("/users_dashboard");
            // Work out the params to send
            const urlParams = new URLSearchParams(window.location.search)
            var siteID = urlParams.get('site_id');
            var dateFrom = urlParams.get('date_from');
            var dateTo = urlParams.get('date_to');
            // Work out which user was clicked
            var workerID = result[selection[0].row][0].replace('User ', '');
            link = link.concat('?site_id=' + siteID + '&worker_id=' + workerID + '&date_from=' + dateFrom
                + '&date_to=' + dateTo);
            window.open(link);
        }
    })
}

// Returns the ith occurrence of m in str
function getPosition(str, m, i) { return str.split(m, i).join(m).length; }

// Removes text elements from a data string
function cleanupData(data) {
    data = data.replaceAll('"', '');
    data = data.replaceAll('=>', ';');
    data = data.replaceAll(', ', ';');
    data = data.replaceAll('{', '');
    data = data.replaceAll('}', '');
    return data;
}

// This takes a string of data and splits it into two axes' data
// e.g. data = "User 1;12;User 2;13", so...
// firstAxis = ["User 1", "User 2"], secondAxis = ["12", "13"]
function generateAxisData(data) {
    var isFirst = true;
    var firstVal = "";
    var firstAxis = [];
    var secondVal = "";
    var secondAxis = [];
    for (let i = 0; i < data.length; i++) {
        var letter = data[i];
        if (letter != ';') {
            if (isFirst) {
                firstVal = firstVal.concat(letter);
            }
            else {
                secondVal = secondVal.concat(letter);
            }
        }
        else {
            if (isFirst) {
                firstAxis.push(firstVal);
                firstVal = "";
            }
            else {
                secondAxis.push(secondVal);
                secondVal = "";
            }
            isFirst = !isFirst
        }
    }
    secondAxis.push(secondVal);
    return [firstAxis, secondAxis];
}

function strftime(sFormat, date) {
    if (!(date instanceof Date)) date = new Date();
    var nDay = date.getDay(),
        nDate = date.getDate(),
        nMonth = date.getMonth(),
        nYear = date.getFullYear(),
        nHour = date.getHours(),
        aDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        aMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        aDayCount = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334],
        isLeapYear = function () {
            return (nYear % 4 === 0 && nYear % 100 !== 0) || nYear % 400 === 0;
        },
        getThursday = function () {
            var target = new Date(date);
            target.setDate(nDate - ((nDay + 6) % 7) + 3);
            return target;
        },
        zeroPad = function (nNum, nPad) {
            return ((Math.pow(10, nPad) + nNum) + '').slice(1);
        };
    return sFormat.replace(/%[a-z]/gi, function (sMatch) {
        return (({
            '%a': aDays[nDay].slice(0, 3),
            '%A': aDays[nDay],
            '%b': aMonths[nMonth].slice(0, 3),
            '%B': aMonths[nMonth],
            '%c': date.toUTCString(),
            '%C': Math.floor(nYear / 100),
            '%d': zeroPad(nDate, 2),
            '%e': nDate,
            '%F': date.toISOString().slice(0, 10),
            '%G': getThursday().getFullYear(),
            '%g': (getThursday().getFullYear() + '').slice(2),
            '%H': zeroPad(nHour, 2),
            '%I': zeroPad((nHour + 11) % 12 + 1, 2),
            '%j': zeroPad(aDayCount[nMonth] + nDate + ((nMonth > 1 && isLeapYear()) ? 1 : 0), 3),
            '%k': nHour,
            '%l': (nHour + 11) % 12 + 1,
            '%m': zeroPad(nMonth + 1, 2),
            '%n': nMonth + 1,
            '%M': zeroPad(date.getMinutes(), 2),
            '%p': (nHour < 12) ? 'AM' : 'PM',
            '%P': (nHour < 12) ? 'am' : 'pm',
            '%s': Math.round(date.getTime() / 1000),
            '%S': zeroPad(date.getSeconds(), 2),
            '%u': nDay || 7,
            '%V': (function () {
                var target = getThursday(),
                    n1stThu = target.valueOf();
                target.setMonth(0, 1);
                var nJan1 = target.getDay();
                if (nJan1 !== 4) target.setMonth(0, 1 + ((4 - nJan1) + 7) % 7);
                return zeroPad(1 + Math.ceil((n1stThu - target) / 604800000), 2);
            })(),
            '%w': nDay,
            '%x': date.toLocaleDateString(),
            '%X': date.toLocaleTimeString(),
            '%y': (nYear + '').slice(2),
            '%Y': nYear,
            '%z': date.toTimeString().replace(/.+GMT([+-]\d+).+/, '$1'),
            '%Z': date.toTimeString().replace(/.+\((.+?)\)$/, '$1')
        }[sMatch] || '') + '') || sMatch;
    });
}