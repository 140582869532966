import MapConfig from '../config/map_config';
import Config from "../config/constants";
import {
    fitMapToBounds, perimeterAjaxCall,
} from './map_ajax';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import {getInitialSite, siteId} from "./site_management";
import Swal from 'sweetalert2';


document.addEventListener("turbolinks:load", () => {
    if ($('#draw_perimeter_map').length) {
        $.when(getInitialSite()).done(() => {
            initiateDrawPerimeterMap();
        })
        saveGeofence();
    }
})

// Save the geofence when clicking the Save button
const saveGeofence = () => {
    const save_geofence_button = $('.geofence-save-button');
    const siteId = document.getElementById("site_id").value;
    const companyId = document.getElementById('company_id').value;

    save_geofence_button.click((e) => {
        Swal.fire({
            title: 'Add Perimeter To Site',
            text: "Please enter a descriptive name for the perimeter:",
            input: "text",
            showCancelButton: true
        }).then((result) => {
            if (result.isConfirmed) {
                let array_of_long_lats = document.getElementById('geofence_array').value;

                if (array_of_long_lats) {
                    let url = `${Config.apiUrl}map_sites/save_perimeter?apikey=${Config.apiKey}&site_id=${siteId}`

                    $.ajax({
                        type: 'POST',
                        data: { 'geofence_array': array_of_long_lats, 'perimeter_name': result.value },
                        dataType: "json",
                        url: url,
                        success: function(data) {
                            Swal.fire("Done!", `Perimeter successfully saved!`, "success");
                            window.location = data.redirect_link
                        },
                    });
                }
                else {
                    alert("Cannot save zone. A zone needs a shape.");
                }
            }
        })
    })
}

// need the map object to set the AJAX calls
// on the window method of setInterval
let drawPerimeterMap;

const initiateDrawPerimeterMap = () => {
    const siteId = document.getElementById("site_id").value;
    const siteCoordinates = document.getElementById("site_coordinates").value;
    mapboxgl.accessToken = Config.mapboxAccessToken;
    const map = new mapboxgl.Map({
        container: 'draw_perimeter_map',
        style: MapConfig.regularStyle,
        center: siteCoordinates.split(" "),
        zoom: MapConfig.startingZoom,
        minZoom: MapConfig.zoomOutMax,
        maxZoom: MapConfig.zoomInMax
    });

    fitMapToBounds(map, siteId, siteCoordinates);

    // Change controls depending on if it is a walkway or a hazard zone
    var controls = {
        polygon: true,
        trash: true
    }

    const draw = new MapboxDraw({
        ...MapboxDraw.modes,
        displayControlsDefault: false,
        // Select which mapbox-gl-draw control buttons to add to the map.
        controls: controls,

        // --------- Style for the drawing tool to make it more visible on the map -----------
        styles: [
            // ACTIVE (being drawn)
            // line stroke
            {
                "id": "gl-draw-line",
                "type": "line",
                "filter": ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
                "layout": {
                    "line-cap": "round",
                    "line-join": "round"
                },
                "paint": {
                    "line-color": "#1c1c1c",
                    "line-dasharray": [0.2, 2],
                    "line-width": 2
                }
            },
            // polygon fill
            {
                "id": "gl-draw-polygon-fill",
                "type": "fill",
                "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
                "paint": {
                    "fill-color": "#1c1c1c",
                    "fill-outline-color": "#1c1c1c",
                    "fill-opacity": 0.5
                }
            },
            // polygon mid points
            {
                'id': 'gl-draw-polygon-midpoint',
                'type': 'circle',
                'filter': ['all',
                    ['==', '$type', 'Point'],
                    ['==', 'meta', 'midpoint']],
                'paint': {
                    'circle-radius': 3,
                    'circle-color': '#fbb03b'
                }
            },
            // polygon outline stroke
            // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
            {
                "id": "gl-draw-polygon-stroke-active",
                "type": "line",
                "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
                "layout": {
                    "line-cap": "round",
                    "line-join": "round"
                },
                "paint": {
                    "line-color": "#1c1c1c",
                    "line-dasharray": [0.2, 2],
                    "line-width": 2
                }
            },
            // vertex point halos
            {
                "id": "gl-draw-polygon-and-line-vertex-halo-active",
                "type": "circle",
                "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
                "paint": {
                    "circle-radius": 5,
                    "circle-color": "#FFF"
                }
            },
            // vertex points
            {
                "id": "gl-draw-polygon-and-line-vertex-active",
                "type": "circle",
                "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
                "paint": {
                    "circle-radius": 3,
                    "circle-color": "#1c1c1c",
                }
            },
        ]
        // --------- End Styling -----------
    });

    map.addControl(draw);
    map.on('draw.create', updateArea);
    map.on('draw.delete', updateArea);
    map.on('draw.update', updateArea);

    map.addControl(new mapboxgl.FullscreenControl());
    map.addControl(new mapboxgl.NavigationControl());
    map.addControl(new mapboxgl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
            },
            trackUserLocation: true
        })
    );

    // Called when the user completes the polygon shape on the map
    function updateArea(e) {
        const data = draw.getAll();
        if (data.features.length > 0) {
            let array_of_long_lats = data.features[0].geometry.coordinates[0];

            if (array_of_long_lats.length > 50){
                window.alert("A zone can have a maximum of 50 points.");
            } else{
                // Save the shape in the geofence_array element to use when saving
                document.getElementById('geofence_array').value = array_of_long_lats
            }
        }
    }
    //**************************************************
    //**************************************************


    //**************************************************
    //**************************************************
    // MAP EVENT LISTENERS
    map.on('mousemove', (e) => {
        document.getElementById('latlong-text').innerText =
            // `e.lngLat` is the longitude, latitude geographical position of the event.
            Number(e.lngLat.lat).toFixed(4) + ', ' + Number(e.lngLat.lng).toFixed(4)
    });

    map.on('load', async () => {
        var layerList = document.getElementById('style-menu');
        var inputs = layerList.getElementsByTagName('input');

        async function switchLayer(layer) {
            var layerId = layer.target.id;
            if (layerId == "default") {
                map.setStyle('mapbox://styles/sroberts95/ckjssbjs10v9519ozm81mugy9');
            } else {
                map.setStyle('mapbox://styles/mapbox/' + layerId);
            }
        }

        for (var i = 0; i < inputs.length; i++) {
            inputs[i].onclick = switchLayer;
        }
    });
    //**************************************************
    //**************************************************
    drawPerimeterMap = map;
}

export { drawPerimeterMap };
