import MapConfig from '../config/map_config';
import Config from "../config/constants";
import mapboxgl from 'mapbox-gl';
import {querySelector} from "@creativebulma/bulma-collapsible/src/js/utils/dom";
import Integer from "jsts/java/lang/Integer";
import {perimeterAjaxCall} from "./map_ajax";

document.addEventListener("turbolinks:load", () => {
    if ($('#site_map').length) {
        initiateConfigureMap();
    }
})

// need the map object to set the AJAX calls
// on the window method of setInterval
let siteMapObject;
let clickCount = 0;

const initiateConfigureMap = () => {
    const siteId = document.getElementById("site_id").value;
    const companyId = document.getElementById('company_id').value;
    const siteCoordinates = document.getElementById("site_coordinates").value;
    mapboxgl.accessToken = Config.mapboxAccessToken;
    // Make LatLongs that are incorrect, so the user has to input a correct latlong
    const map = new mapboxgl.Map({
        container: 'site_map',
        style: 'mapbox://styles/sroberts95/ckjssbjs10v9519ozm81mugy9',
        center: siteCoordinates.split(" "),
        zoom: MapConfig.startingZoom,
        minZoom: MapConfig.zoomOutMax,
        maxZoom: MapConfig.zoomInMax
    });

    map.addControl(new mapboxgl.FullscreenControl());
    map.addControl(new mapboxgl.NavigationControl());
    map.addControl(new mapboxgl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
            },
            trackUserLocation: true
        })
    );
    //**************************************************
    //**************************************************


    //**************************************************
    //**************************************************
    // MAP EVENT LISTENERS
    map.on('load', () => {
        var layerList = document.getElementById('style-menu');
        var inputs = layerList.getElementsByTagName('input');
        function switchLayer(layer) {
            var layerId = layer.target.id;
            if(layerId == "default"){
                map.setStyle('mapbox://styles/sroberts95/ckjssbjs10v9519ozm81mugy9');
                perimeterAjaxCall(map, null, siteId, true, true);
            }else{
                map.setStyle('mapbox://styles/mapbox/' + layerId);
                perimeterAjaxCall(map, null, siteId, true, true);
            }
        }

        for (var i = 0; i < inputs.length; i++) {
            inputs[i].onclick = switchLayer;
        }
        perimeterAjaxCall(map, null, siteId, true, true);
    });

    // Event listener when clicking map
    map.on('click', (e) => {
        createRFLayer(e.lngLat.lat, e.lngLat.lng, map, e);
        var latitudeField = document.getElementById('latitude-box');
        var longitudeField = document.getElementById('longitude-box');
        latitudeField.value = e.lngLat.lat;
        longitudeField.value = e.lngLat.lng;
    });

    //**************************************************
    //**************************************************
    siteMapObject = map;
}

// To be replaced with a proper API key once we have an account
var cloud_rf_key = Config.cloudrfApiKey;

// To be replaced with our own transmitter / antenna etc details
var some_radio = {
    "site": "PMR",
    "network": "DEMO",
    "transmitter": {
        "lat": 38.939634,
        "lon": 1.398117,
        "alt": 2,
        "frq": 446,
        "txw": 5,
        "bwi": 0.1
    },
    "receiver": {
        "lat": 0,
        "lon": 0,
        "alt": 1,
        "rxg": 2.15,
        "rxs": -99
    },
    "antenna": {
        "txg": 2.15,
        "txl": 0,
        "ant": 39,
        "azi": 0,
        "tlt": 0,
        "hbw": 0,
        "vbw": 0,
        "pol": "v"
    },
    "model": {
        "pm": 1,
        "pe": 2,
        "cli": 6,
        "ked": 0,
        "rel": 95,
        "ter": 4
    },
    "environment": {
        "clm": 1,
        "cll": 2,
        "mat": 0.5
    },
    "output": {
        "units": "metric",
        "col": "RAINBOW.dBm",
        "out": 2,
        "ber": 0,
        "mod": 0,
        "nf": -114,
        "res": 16,
        "rad": 2
    }
};

function createRFLayer(lat,lon,map, mapClickEvent){
    /*
    1. Fetch a template
    2. Apply any variables like latitude, longitude, altitude
    3. Send to api
    4. Put on map
    */
    some_radio.transmitter.lat = lat;
    some_radio.transmitter.lon = lon;

    var template = JSON.stringify(some_radio);
    CloudRFAreaAPI(template,map, mapClickEvent);
}

function CloudRFAreaAPI(request,slippyMap, mapClickEvent){
    var xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.addEventListener("readystatechange", function() {
        if(this.readyState === 4) {
            AreaCallback(this.responseText,slippyMap, mapClickEvent);
        }
    });
    xhr.open("POST", "https://api.cloudrf.com/area");
    xhr.setRequestHeader("key", cloud_rf_key);
    xhr.send(request);
}

// Leaflet and openlayers methods
function AreaCallback(text,map, mapClickEvent){
    var json =  JSON.parse(text);
    var boundsNESW = json.bounds; // CloudRF uses NORTH,EAST,SOUTH,WEST
    if(map.isSourceLoaded('cloudrf')){
        map.removeLayer('cloudrf-layer');
        map.removeSource('cloudrf');
    }
    map.addSource('cloudrf', {
        'type': 'image',
        'url': json.PNG_Mercator,
        'coordinates': [
            [boundsNESW[3], boundsNESW[0]],
            [boundsNESW[1], boundsNESW[0]],
            [boundsNESW[1], boundsNESW[2]],
            [boundsNESW[3], boundsNESW[2]]
        ]
    });
    map.addLayer({
        id: 'cloudrf-layer',
        'type': 'raster',
        'source': 'cloudrf',
        'paint': {
            'raster-fade-duration': 0,
            'raster-opacity': 0.5
        }
    });
    clickCount = clickCount + 1;
    var number = document.createElement('div');
    number.innerText = clickCount.toString();
    number.style.fontWeight = 'bold';
    number.style.borderRadius = '50%';
    var strippedCoverage = json.coverage.replace('%', '')
    var coverage;
    coverage = Integer.valueOf(strippedCoverage).value;
    console.log(coverage);
    if(coverage > 70){
        number.style.border = '4px solid green';
    }else if (coverage > 50){
        number.style.border = '4px solid yellow'
    }else {
        number.style.border = '4px solid red';
    }
    number.style.paddingLeft = '8px';
    number.style.paddingRight = '8px';
    const markerOptions = {
        anchor: 'bottom',
        element: number
    }
    new mapboxgl.Marker(markerOptions)
        .setLngLat([some_radio.transmitter.lon, some_radio.transmitter.lat])
        .addTo(map);

    var clickHistory = querySelector('#click-history');
    var newBox = document.createElement('div');
    newBox.style.marginTop = '10px';
    newBox.style.padding = '5px';
    newBox.style.borderBottom = '1px solid black';
    newBox.className = "click-history-box"
    newBox.innerHTML =                   `
              <h1 style="font-weight: bold">#${clickCount.toString()}</h1>
              <p><span style="font-weight: bold">Latitude</span>: ${mapClickEvent.lngLat.lat}</p>
              <p><span style="font-weight: bold">Longitude</span>: ${mapClickEvent.lngLat.lng}</p>
              <p><span style="font-weight: bold">Coverage</span>: ${json.coverage}</p>
              <p><span style="font-weight: bold">Area</span>: ${json.area}</p>
            `
    clickHistory.appendChild(newBox);
}

export { siteMapObject };