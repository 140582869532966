window.showBiositeApi = function() {
  var biosite_control = document.getElementById('biosite_api_key_control')
  if (biosite_control != null) {
    biosite_control.classList.remove("is-hidden");
  }
}

window.showGisIntegration = function() {
  var gis_details = document.getElementById('gis-details')
  if (gis_details != null) {
    gis_details.classList.remove("is-hidden");
  }
}
