$(window).on('load', function () {
    listenForDisconnectMinimise();
});

$(document).on('turbolinks:load', () => {
    listenForDisconnectMinimise();
})

function listenForDisconnectMinimise(){
    $('#minimise-disconnect').click((e) => {
        $('#disconnect-banner').hide();
        $('#maximise-disconnect').show();
    });
    $('#maximise-disconnect').click((e) => {
        $('#maximise-disconnect').hide();
        $('#disconnect-banner').show();
    });
}