import Config from "../custom/config/constants";

$(document).on('turbolinks:load', () => {
  const $siteDropdown = $(".new-group-site-dropdown");
  const $workersDiv = $(".workers-field");
  listenForSiteChange($siteDropdown, $workersDiv);
});

const listenForSiteChange = ($siteDropdown, $workersDiv) => {
  $siteDropdown.change((e) => {
    let siteId = $(e.target).val();
    var $select = $('#group-user_select').selectize();
    var selectize = $select[0].selectize;

    $.ajax({
      type: 'GET',
      url: `${Config.apiUrl}users?apikey=${Config.apiKey}&site_id=${siteId}`,
      dataType: "json",
      success: data => {
        selectize.clearOptions();
        selectize.clear();
        $.each(data.allocation_data, (index, user_id) => {
          selectize.addOption({
            text: user_id,
            value: user_id
          })
        });
        selectize.refreshOptions();
      },
      error: data => {
        alert("Error trying to retrieve workers data. Please refresh the page and try again.");
      }
    });
  });
}
