import Config from "../custom/config/constants";

$(document).on('turbolinks:load', () => {
  if(document.getElementById('dropdown-trigger')){
    document.getElementById('dropdown-trigger').addEventListener('click', () => {
      let dropdownMenu = $("#dropdown-menu");
      dropdownMenu.toggle();
    });
  }

  if(document.getElementById('user-account-trigger')){
    document.getElementById('user-account-trigger').addEventListener('click', () => {
      let dropdownMenu = $("#user-account-menu");
      dropdownMenu.toggle();
    });
  }

  const $siteDropdown = $(".site-dash-site-dropdown");
  const $countTextHolder = $("#site-dash-active-users");
  const $eventStatusHolder = $("#site-dash-event-status");
  const $networkStatusHolder = $("#site-dash-network-status");
  if ($siteDropdown.length) {
    listenForSiteChange($siteDropdown, $countTextHolder, $eventStatusHolder, $networkStatusHolder);
  }

  const $geofenceStatus = $(".site-dash-geofence-status");
  const $lineChartDiv = $("#site-dash-zone-line-chart-div");
  if ($geofenceStatus.length) {
    listenForStatusClick($geofenceStatus, $lineChartDiv);
  }
});

const listenForStatusClick = ($geofenceStatus, $lineChartDiv) => {
  $geofenceStatus.click(function(event) {
    let fence = $(this).text().trim();
    let siteId = document.getElementById("site_id").value;
    let toDate = document.getElementById("date_to").value;
    let fromDate = document.getElementById("date_from").value;
    let timeDifference = document.getElementById('time_difference').value;
    let is_machine = document.getElementById('event').value == 'Machine Zoning';
    let placeholder = $("#site-dash-geofence-placeholder");
    let url = ''

    if (is_machine) {
      url = `${Config.apiUrl}dashboard/create_machine_zone_chart?apikey=${Config.apiKey}&site_id=${siteId}&fence_name=${fence}&from_date=${fromDate}&to_date=${toDate}&time_difference=${timeDifference}&is_machine=${is_machine}`
    }else {
      url = `${Config.apiUrl}dashboard/create_geofence_chart?apikey=${Config.apiKey}&site_id=${siteId}&fence_name=${fence}&from_date=${fromDate}&to_date=${toDate}&time_difference=${timeDifference}&is_machine=${is_machine}`
    }

    $.ajax({
      type: 'GET',
      url: url,
      dataType: "json",
      async: true,
      beforeSend: function () {
        $("#coverScreen").css("display", "block");
      },
      success: data => {
        if (!is_machine) {
          $('#breach-table-container').show();
          // Table
          let $table = $("#breach-table");
          $table.show();
          let $tbody = $("#breach-table-body");
          $tbody.children('tr').remove();
          // Add rows
          data.group_breaches.forEach((item, index) => {
            $tbody.append('<tr />').children('tr:last')
                .append(`<td>${item.name}</td>`);
            $tbody.children('tr:last').append(`<td>${accessColour(item.access_level)}</td>`);
            $tbody.children('tr:last').append(`<td>${item.zone_interactions}</td>`);
            // Set colour of cell to red if there are >0 breaches and the access level is not green
            if ((item.extended_breaches > 0) && ((item.access_level === "red" || item.access_level === 3 || item.access_level === "amber" || item.access_level === 2))) {
              $tbody.children('tr:last').append(`<td style="background-color: rgba(255, 0, 0, 0.2);">${item.extended_breaches}</td>`);
            } else {
              $tbody.children('tr:last').append(`<td>${item.extended_breaches}</td>`);
            }
            if (item.repeated_breaches > 0 && ((item.access_level === "red" || item.access_level === 3 || item.access_level === "amber" || item.access_level === 2)))  {
              $tbody.children('tr:last').append(`<td style="background-color: rgba(255, 0, 0, 0.2);">${item.repeated_breaches}</td>`);
            } else {
              $tbody.children('tr:last').append(`<td>${item.repeated_breaches}</td>`);
            }
          });
        }

        $lineChartDiv.attr("style", "display: inline !important");
        let green_chart = Chartkick.charts["site-dash-green-chart"];
        green_chart.updateData(data.green_records);
        green_chart.responsive = true;
        green_chart.redraw();
        let red_chart = Chartkick.charts["site-dash-red-chart"];
        red_chart.updateData(data.red_records);
        red_chart.responsive = true;
        red_chart.redraw();
        $("#coverScreen").hide();
      }
    })
  })
}

const listenForSiteChange = ($siteDropdown, $countTextHolder, $eventStatusHolder, $networkStatusHolder) => {
  // When we change site on the Site Dashboard, update the live information for that site

  $siteDropdown.change((e) => {
    let siteId = $(e.target).val();

    $.ajax({
      type: 'GET',
      url: `${Config.apiUrl}dashboard/get_active_users_on_site?apikey=${Config.apiKey}&site_id=${siteId}`,
      dataType: "json",
      success: data => {
        let count = data.active_count
        $countTextHolder.empty().append(count);
      },
      error: data => {
        alert("Error trying to retrieve active users data. Please refresh the page and try again.");
        $countTextHolder.empty().append("0");
      }
    });

    $.ajax({
      type: 'GET',
      url: `${Config.apiUrl}dashboard/get_event_status_for_site?apikey=${Config.apiKey}&site_id=${siteId}`,
      dataType: "json",
      success: data => {
        let value = data.event_status
        if (value === 2){
          $eventStatusHolder.empty().append("<i class='fas fa-circle has-text-danger'></i>");
        } else if (value === 1){
          $eventStatusHolder.empty().append("<i class='fas fa-circle has-text-warning'></i>");
        }else {
          $eventStatusHolder.empty().append("<i class='fas fa-circle has-text-success'></i>");
        }
      },
      error: data => {
        alert("Error trying to retrieve site event status. Please refresh the page and try again.");
      }
    })

    $.ajax({
      type: 'GET',
      url: `${Config.apiUrl}dashboard/get_network_status_for_site?apikey=${Config.apiKey}&site_id=${siteId}`,
      dataType: "json",
      success: data => {
        let value = data.network_status
        $networkStatusHolder.empty().append(value);
      },
      error: data => {
        alert("Error trying to retrieve site network status. Please refresh the page and try again.");
      }
    })
  });
}

function accessColour(val) {
  // The geofences and access_groups_geofences return different values from their enums, so need to check both
  if (val === "red" || val === 3) {
    return "<i class='fas fa-circle has-text-danger'></i>"
  }
  else if (val === "amber" || val === 2) {
    return "<i class='fas fa-circle has-text-warning'></i>"
  }
  // Runs for "green", 1 and 0 as all users are allowed in productivity zones
  else {
    return "<i class='fas fa-circle has-text-success'></i>"
  }
}