import Config from "./config/constants";

$(document).on('turbolinks:load', () => {
    const zone_interaction_modal = $("#zone-interaction-modal");
    if (zone_interaction_modal.length) {
        listenForCompanyChange();
        listenForSiteChange();
    }
})

function listenForCompanyChange() {
    let company_dropdown = $(".company-dropdown");
    let site_dropdown = $(".site-dropdown");
    company_dropdown.on("change", (e) => {
        let company_id = $(e.target).val();

        $.ajax({
            type: 'GET',
            url: `${Config.apiUrl}companies/get_sites_for_company?apikey=${Config.apiKey}&company_id=${company_id}`,
            dataType: "json",
            success: data => {
                let sitesDropdownHtml = "";
                $.each(data.sites, (index, site) => {
                    if(index == 0){
                        changePersonnelZones(site.id);
                    }

                    sitesDropdownHtml +=  `<option value="${site.id}">${site.name}</option>`
                });
                site_dropdown.empty().append(sitesDropdownHtml);
            },
            error: data => {
                alert("Error trying to retrieve sites data. Please refresh the page and try again.");
            }
        });
    });
}

function listenForSiteChange() {
    let site_dropdown = $(".site-dropdown");
    site_dropdown.on("change", (e) => {
        let site_id = $(e.target).val();
        changePersonnelZones(site_id);
    })
}

function changePersonnelZones(site_id) {
    let personnel_zone_dropdown = $(".personnel-zone-dropdown")
    $.ajax({
        type: 'GET',
        url: `${Config.apiUrl}geofences?apikey=${Config.apiKey}&site_id=${site_id}`,
        dataType: "json",
        success: data => {
            let geofenceDropdownHtml = "";
            geofenceDropdownHtml +=  `<option value="-1">All</option>`;
            $.each(data.geofence_data, (index, geofence) => {
                geofenceDropdownHtml +=  `<option value="${geofence.id}">${geofence.name}</option>`
            });
            personnel_zone_dropdown.empty().append(geofenceDropdownHtml);
        },
        error: data => {
            alert("Error trying to retrieve geofence data. Please refresh the page and try again.");
        }
    });
}