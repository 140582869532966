import Config from "../custom/config/constants";

$(document).on('turbolinks:load', () => {
  const $siteDropdown = $(".worker-groups-site-dropdown");
  const $groupDropdown = $(".worker-groups-group-dropdown");
  if ($siteDropdown.length && $groupDropdown.length) {
    listenForSiteChange($siteDropdown, $groupDropdown);
  }
});

const listenForSiteChange = ($siteDropdown, $groupDropdown) => {
  $siteDropdown.change((e) => {
    let siteId = $(e.target).val();

    $.ajax({
      type: 'GET',
      url: `${Config.apiUrl}groups?apikey=${Config.apiKey}&site_id=${siteId}`,
      dataType: "json",
      success: data => {
        let groupsDropdownHtml = "";
        data.groups_data.forEach((item, index) => {
          groupsDropdownHtml +=  `<option value="${item.group_id}">${item.group_name}</option>`
        });
        $groupDropdown.empty().append(groupsDropdownHtml);
      },
      error: data => {
        alert("Error trying to retrieve groups data. Please refresh the page and try again.");
      }
    });
  });
}
