import MapConfig from '../config/map_config';
import Config from "../config/constants";
import Swal from "sweetalert2";

// import {
//   imageDataAjaxCall,
//   perimeterAjaxCall,
//   geofenceAjaxCallWithDate, imageDataAjaxCallWithDateRange,
// } from './map_ajax';
// import {siteId, siteCoordinates, getInitialSite, updateSiteCoordinates} from './site_management';
// import mapboxgl from 'mapbox-gl';
// import MapboxDraw from "@mapbox/mapbox-gl-draw";
// import moment from 'moment';
// // import Gantt from "frappe-gantt";
// // import date_utils from "frappe-gantt/src/date_utils";
// // import Bar from "frappe-gantt/src/bar";
// import * as jsts from 'jsts'
//
// let taskNames = []
// let ganttObject;
// let isNewZone;
//
// const draw = new MapboxDraw({
//   ...MapboxDraw.modes,
//   displayControlsDefault: false
// });
//
// document.addEventListener("turbolinks:load", () => {
//   if ($('#planning-map').length) {
//     // Scroll to the center of the timeline
//     document.querySelector('.active').scrollIntoView({
//       behavior: 'auto',
//       block: 'center',
//       inline: 'center'
//     });
//     $.when(getInitialSite()).done(() => {
//       initiateMap();
//       setupGanttChart(siteId, "Current");
//       let dots = document.getElementsByClassName('dot');
//       for (let i = 0; i < dots.length; i++){
//         dots[i].addEventListener('click', dotClicked);
//       }
//     })
//     let polygonButton = document.getElementById('polygon-button');
//     let lineButton = document.getElementById('line-button');
//     let trashButton = document.getElementById('trash-button');
//     polygonButton.onclick = function () {
//       lineButton.classList.remove("active-draw-button");
//       polygonButton.classList.add("active-draw-button");
//       draw.changeMode('draw_polygon');
//     }
//     lineButton.onclick = function () {
//       polygonButton.classList.remove("active-draw-button");
//       lineButton.classList.add("active-draw-button");
//       draw.changeMode('draw_line_string');
//     }
//     trashButton.onclick = function () {
//       lineButton.classList.remove("active-draw-button");
//       polygonButton.classList.remove("active-draw-button");
//       draw.deleteAll();
//     }
//     document.getElementById('map_site_id').onchange = function () {
//       var e = document.getElementById("map_site_id");
//       var siteId = e.value;
//       updateSiteCoordinates(siteId).then(response => {initiateMap(response.coordinates, null); setupGanttChart(siteId);});
//     }
//     document.getElementById('upload-button').onclick = function () {
//       let popoutMenu = document.getElementById('upload-popout-menu');
//       popoutMenu.classList.remove('animate__slideOutRight');
//       popoutMenu.classList.add('animate__animated', 'animate__slideInRight',  'animate__faster');
//       popoutMenu.setAttribute('style', 'visibility:visible !important');
//       document.getElementById('timeline').setAttribute('style', 'display:none !important');
//     }
//     document.getElementById('upload-close-popout').onclick = function () {
//       document.getElementById('upload-popout-menu').classList.remove('animate__slideInRight');
//       document.getElementById('upload-popout-menu').classList.add('animate__animated', 'animate__slideOutRight',  'animate__faster');
//       document.getElementById('timeline').setAttribute('style', 'display:flex !important');
//     }
//     document.getElementById('planning-popout-go-to-permit').onclick = function () {
//       let permit_id = document.getElementById('permit_id').value;
//       let geofence_id = document.getElementById('clicked_geofence').value;
//       let url = window.location.href;
//       if (!permit_id || permit_id == ""){
//         alert("There is no permit linked to this zone.");
//       }else{
//         url = url.replace("planning", "permits/" + permit_id.toString() + "/edit?geofences=" + geofence_id.toString() + "site_id=" + siteId.toString());
//         window.location.href = url;
//       }
//     }
//   }
// })
//
// let geofenceIds = [];
// let mapObject;
//
// const initiateMap = (coordinates, date_range) => {
//   let site_coords = null;
//   if (coordinates){
//     site_coords = coordinates.split(" ");
//   }else {
//     site_coords = siteCoordinates;
//   }
//   mapboxgl.accessToken = Config.mapboxAccessToken;
//   const map = new mapboxgl.Map({
//     container: 'planning-map',
//     style: 'mapbox://styles/sroberts95/ckjssbjs10v9519ozm81mugy9',
//     center: site_coords,
//     zoom: MapConfig.startingZoom,
//     minZoom: MapConfig.zoomOutMax,
//     maxZoom: MapConfig.zoomInMax
//   });
//
//   map.addControl(draw);
//   map.on('draw.create', updateArea);
//   map.on('draw.delete', updateArea);
//   map.on('draw.update', updateArea);
//
//
//   // Called when the user completes the polygon shape on the map
//   function updateArea(e) {
//     isNewZone = true;
//     removeResourceRows();
//     const data = draw.getAll();
//     let shapeType = data.features[0].geometry.type;
//     if (data.features.length > 0) {
//       let popoutMenu = document.getElementById('planning-popout-menu');
//       popoutMenu.classList.remove('animate__slideOutRight');
//       popoutMenu.classList.add('animate__animated', 'animate__slideInRight',  'animate__faster');
//       popoutMenu.setAttribute('style', 'visibility:visible !important');
//       document.getElementById('timeline').setAttribute('style', 'display:none !important');
//       document.getElementById('dependent').value = null;
//       document.getElementById('default_access_level').value = 'not_allowed';
//       document.getElementById('popout-zone-name').setAttribute('style', 'display:none !important');
//       document.getElementById('new-zone-name').setAttribute('style', 'display:block !important');
//       // Clear change log as this is a new zone
//       let changeLogTable = document.getElementById('popout-change-log-table');
//       let newTbody = document.createElement('tbody');
//       newTbody.id = 'popout-change-log-table';
//       changeLogTable.parentNode.replaceChild(newTbody, changeLogTable);
//       let array_of_long_lats = data.features[0].geometry.coordinates;
//       if (shapeType === "LineString"){
//         document.getElementById('popout-line-offset-container').setAttribute('style', 'display:block !important');
//       }
//       document.getElementById('geofence_array').value = array_of_long_lats;
//     }
//   }
//
//   map.addControl(new mapboxgl.FullscreenControl());
//   map.addControl(new mapboxgl.NavigationControl());
//   map.addControl(new mapboxgl.GeolocateControl({
//     positionOptions: {
//       enableHighAccuracy: true
//     },
//     trackUserLocation: true
//     })
//   );
//   //**************************************************
//   //**************************************************
//
//
//   //**************************************************
//   //**************************************************
//   // MAP EVENT LISTENERS
//   map.on('load', () => {
//     // horizontal mouse wheel scrolling for timeline
//     (function() {
//       function scrollHorizontally(e) {
//         e = window.event || e;
//         var delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
//         document.getElementById('timeline').scrollLeft -= (delta * 40); // Multiplied by 40
//         e.preventDefault();
//       }
//       if (document.getElementById('timeline').addEventListener) {
//         // IE9, Chrome, Safari, Opera
//         document.getElementById('timeline').addEventListener('mousewheel', scrollHorizontally, false);
//         // Firefox
//         document.getElementById('timeline').addEventListener('DOMMouseScroll', scrollHorizontally, false);
//       } else {
//         // IE 6/7/8
//         document.getElementById('timeline').attachEvent('onmousewheel', scrollHorizontally);
//       }
//     })();
//     let range_date_param;
//     if (date_range){
//       range_date_param = date_range
//     }else{
//       range_date_param = document.getElementById('date_range_param').value;
//     }
//     geofenceIds = document.getElementById('geofences').value;
//
//     // Populate data on the map
//     populateMap(map, range_date_param);
//   });
//   //**************************************************
//   //**************************************************
//   mapObject = map;
// }
//
// function dotClicked(){
//   let dateValues = this.querySelector('span').dataset.info.split(":")[0];
//   let start_date = dateValues.split(" - ")[0];
//   let end_date = dateValues.split(" - ")[1];
//   getZonesForChart(start_date, end_date, ganttObject, siteId, false);
//   initiateMap(null, start_date + " to " + end_date);
//   let dots = document.getElementsByClassName('dot');
//   for (let i = 0; i < dots.length; i++){
//     dots[i].classList.remove('active');
//   }
//   this.classList.add('active');
// }
//
// function setupGanttChart(siteId, dateSelected){
//   // Set up a dummy initial task (this is used by the Gantt library to determine the current date)
//   let start_date, end_date;
//   // Subtract 6 and add 8 as momentjs startOf('week') and endOf('week') return a Sunday whereas rails DateTime.at_beginning_of_week returns a Monday
//   // so need them to match
//   start_date = moment().startOf('week').subtract(6, 'days').format("YYYY-MM-DD");
//   end_date = moment().endOf('week').add(8, 'days').format("YYYY-MM-DD");
//   var tasks = [
//     {
//       id: 'Task 1',
//       name: 'Redesign website',
//       start: start_date,
//       end: end_date,
//       progress: 100
//     }
//   ]
//   let options = {
//     bar_height: 30,
//     step: 24,
//     column_width: Math.floor(window.innerWidth / 21),
//     on_click: function (task) {
//       ganttOnClick(null, task);
//     },
//     on_date_change: function(task, start, end) {
//       ganttOnChange(task, start, end);
//     },
//   }
//   // Create a GanttExtension as we want to override some default behaviour
//   ganttObject = new GanttExtension("#gantt", tasks, options, start_date, end_date);
//   ganttObject.set_start(start_date);
//   ganttObject.set_end(end_date);
//   // hide the gantt initially as if there is no zones for the time period, we dont want to show the dummy task
//   document.getElementById('gantt').setAttribute('style', 'display:none !important');
//   document.getElementById('date_range_param').value = start_date + " to " + end_date
//   getZonesForChart(start_date, end_date, ganttObject, siteId, true);
//   addGanttEventListeners(ganttObject);
// }
//
// // Update the zone details when user changes it on the gantt chart
// function ganttOnChange(task, start, end){
//   let reasonForChange = prompt("Please enter reason for date change:");
//   if (reasonForChange != null && reasonForChange != ""){
//     let previousStartDate = task.start;
//     let previousEndDate = task.end;
//     $.ajax({
//       type: 'GET',
//       url: `${Config.apiUrl}geofences/update_dates?apikey=${Config.apiKey}&site_id=${siteId}&fence_name=${task.name}&date_from=${start}&date_to=${end}&reason=${reasonForChange}&previous_date_from=${previousStartDate}&previous_date_to=${previousEndDate}`,
//       dataType: "json",
//       success: data => {
//         if (document.getElementById('popout-zone-name').innerText === task.name){
//           document.getElementById('popout-date-from').value = moment(start).format('YYYY-MM-DD');
//           document.getElementById('popout-date-to').value = moment(end).format('YYYY-MM-DD');
//         }
//         task.start = moment(start).format('YYYY-MM-DD');
//         task.end = moment(end).format('YYYY-MM-DD');
//         // reload the page so that the zone details are updated when next loading its popout menu
//         location.reload();
//       }
//     })
//   }else{
//     alert("A reason must be given when changing the dates of a task.");
//   }
// }
//
// // Generic event listeners for displaying the gantt chart and popout window
// function addGanttEventListeners(gantt){
//   for (let i = 0; i < gantt.tasks.length; i++){
//     taskNames.push(gantt.tasks[i].name);
//   }
//   let menuBar = document.getElementById('gantt-menu-bar');
//   let container = document.querySelector('.gantt-container');
//   let shrinkGantt = document.getElementById('shrink-gantt');
//   let expandGantt = document.getElementById('expand-gantt');
//   document.getElementById('close-popout').onclick = function () {
//     document.querySelector('.gantt-container').setAttribute('style', 'display:none !important');
//     document.getElementById('gantt-menu-bar').setAttribute('style', 'display:none !important');
//     document.getElementById('timeline').setAttribute('style', 'display:flex !important');
//     document.getElementById('planning-popout-menu').classList.remove('animate__slideInRight');
//     document.getElementById('planning-popout-menu').classList.add('animate__animated', 'animate__slideOutRight',  'animate__faster');
//     document.getElementById('popout-line-offset-container').setAttribute('style', 'display:none !important');
//     resetLayerLines();
//     mapObject.flyTo({
//       center:siteCoordinates,
//       zoom: MapConfig.startingZoom,
//       essential: true // this animation is considered essential with respect to prefers-reduced-motion
//     });
//   }
//   document.getElementById('popout-add-resource').onclick = function() {
//     createResourceSelects();
//   }
//   document.getElementById("expand-gantt").onclick = function () {
//     container.setAttribute('style', 'height:40% !important');
//     menuBar.setAttribute('style', 'bottom: 35% !important');
//     shrinkGantt.setAttribute('style', 'opacity:1 !important');
//     expandGantt.setAttribute('style', 'opacity:0 !important');
//   }
//   document.getElementById("shrink-gantt").onclick = function () {
//     container.setAttribute('style', 'height:20% !important');
//     shrinkGantt.setAttribute('style', 'opacity:0 !important');
//     expandGantt.setAttribute('style', 'opacity:1 !important');
//     menuBar.setAttribute('style', 'bottom:17% !important');
//   }
//   document.getElementById("minimise-gantt").onclick = function () {
//     container.setAttribute('style', 'height:0 !important');
//     shrinkGantt.setAttribute('style', 'opacity:1 !important');
//     expandGantt.setAttribute('style', 'opacity:1 !important');
//     menuBar.setAttribute('style', 'bottom:0 !important');
//     menuBar.setAttribute('style', 'display:none !important');
//     document.getElementById('timeline').setAttribute('style', 'display:flex !important')
//   }
//   document.getElementById('planning-popout-save').onclick = function () {
//     savePopoutForm();
//   }
//   document.getElementById('planning-popout-delete').onclick = function () {
//     if (confirm('Are you sure you want to delete this zone?')){
//       deleteZone();
//     }
//   }
// }
//
function deleteZone(siteId){
  let zone_name = document.getElementById('popout-zone-name').innerText;
  $.ajax({
    type: 'GET',
    url: `${Config.apiUrl}geofences/delete_zone?apikey=${Config.apiKey}`,
    data: { zone_name: zone_name, site_id: siteId },
    dataType: "json",
    success: data => {
      location.reload();
    }
  })
}
//
// function resourceChange(){
//   // Change the people select to show machines instead
//   let peopleSelect = this.parentElement.parentElement.parentElement.querySelector('.resource-specific-select-column').querySelector('.select').querySelector('.dropdown');
//   if (this.value === "Machines") {
//     updateSelectOptions(peopleSelect, "Machines");
//   }else{
//     updateSelectOptions(peopleSelect, "People");
//   }
// }
//
function updateSelectOptions(select, type){
  let options;
  let optionsString = "";
  if (type === "People"){
    options = document.getElementById('roles').value;
  }else{
    options = document.getElementById('machines').value;
  }
  options = options.split(", ");
  for (let i = 0; i < options.length; i++) {
    optionsString += '<option value="' + options[i] + '">' + options[i] + '</option>'
  }
  select.innerHTML = optionsString;
}
//
// // Save the details in the popout form
function savePopoutForm(event, siteId){
  let reasonForChange;
  let canSave = false;
  let coordinates;
  let startDate = document.getElementById('popout-date-from').value;
  let endDate = document.getElementById('popout-date-to').value;
  //let dependsOn = document.getElementById('dependent').value;
  let zoneName = document.getElementById('popout-zone-name').innerText;
  let zone_id = event.target.getAttribute('zone_id');
  let is_machine = event.target.getAttribute('is_machine_zone');
  let accessLevel = document.getElementById('default_access_level').value;
  let speed_limit = document.getElementById('popout-speed-limit').value;
  //let offset = document.getElementById('popout-line-offset').value;
  let permitResources = [];
  let selectedResources = document.getElementsByClassName('popout-resource-row-div');
  if (selectedResources) {
    for (let i = 0; i < selectedResources.length; i++) {
      permitResources.push({type: selectedResources[i].querySelector('.popout-resource-select').value,
        description: selectedResources[i].querySelector('.popout-people-select').value,
        amount: selectedResources[i].querySelector('.amount').value})
    }
  }
  Swal.fire({
    title: "Modify Zone",
    text: `Please enter reason for change:`,
    input: "text",
  }).then((result) => {
    if(result.isConfirmed){
      let url;
      if (is_machine == "true") {
        url = `${Config.apiUrl}geofences/update_machine_zone_details?apikey=${Config.apiKey}&speed_limit=${speed_limit}`
      } else {
        url = `${Config.apiUrl}geofences/update_zone_details?apikey=${Config.apiKey}`
      }

      $.ajax({
        type: 'GET',
        url: url,
        data: { zone_id: zone_id, access_level: accessLevel, site_id: siteId, fence_name: zoneName, date_from: startDate, date_to: endDate,
          permit_resources: permitResources, coordinates: coordinates, reason: result.value},
        dataType: "json",
        success: data => {
          Swal.fire("Done!", "Zone Updated!", "success").then((done) => {
            location.reload();
          });
        }
      })
    }
  })
}
//
function getReasonForChange(){
  let reasonForChange = prompt("Please enter reason for change:");
  if (reasonForChange != null && reasonForChange != ""){
    return reasonForChange;
  }else{
    alert("Save Cancelled");
  }
}

function removeResourceRows(){
  let resourceRows = document.querySelectorAll('.popout-resource-row-div');
  if (resourceRows && resourceRows.length > 0) {
    resourceRows.forEach(item => item.remove());
  }
}
//
// // Set everything that should happen when a user clicks a row of the gantt chart
// function ganttOnClick(gantt, task){
//   resetLayerLines();
//   removeResourceRows();
//   document.getElementById('popout-line-offset-container').setAttribute('style', 'display:none !important');
//   document.querySelector('.gantt-container').setAttribute('style', 'height:0 !important');
//   document.getElementById('gantt-menu-bar').setAttribute('style', 'display:none !important');
//   document.getElementById('shrink-gantt').setAttribute('style', 'opacity:1 !important');
//   let popoutMenu = document.getElementById('planning-popout-menu');
//   if (mapObject.getLayer(task.name + '_outline')) {
//     mapObject.setPaintProperty(task.name + '_outline', 'line-width', 5);
//   }
//   getZoneDetails(task);
//   popoutMenu.classList.remove('animate__slideOutRight');
//   popoutMenu.classList.add('animate__animated', 'animate__slideInRight',  'animate__faster');
//   popoutMenu.setAttribute('style', 'visibility:visible !important');
//   flyToZone(task);
// }
//
// function getZoneDetails(task){
//   document.getElementById('popout-zone-name').innerText = task.name;
//   document.getElementById('popout-date-from').value = task.start;
//   document.getElementById('popout-date-to').value = task.end;
//   $.ajax({
//     type: 'GET',
//     url: `${Config.apiUrl}geofences/get_zone_details?apikey=${Config.apiKey}&site_id=${siteId}&fence_name=${task.name}`,
//     dataType: "json",
//     success: data => {
//       if (data.geofence_data){
//         document.getElementById('dependent').value = data.geofence_data.depends_on;
//         document.getElementById('default_access_level').value = data.geofence_data.access_level;
//         if (data.geofence_data.is_access_way){
//           document.getElementById('popout-line-offset-container').setAttribute('style', 'display:block !important');
//           document.getElementById('popout-line-offset').value = data.geofence_data.offset;
//         }
//         document.getElementById('clicked_geofence').value = data.geofence_data.id;
//         document.getElementById('clicked_geofence_array').value = data.geofence_array;
//         document.getElementById('permit_id').value = data.geofence_data.permit_id;
//         if (data.geofence_data.permit_resources){
//           let resource_data = data.geofence_data.permit_resources;
//           resource_data.forEach(item => createResourceSelects(item));
//         }
//         let changeLogTable = document.getElementById('popout-change-log-table');
//         let newTbody = document.createElement('tbody');
//         newTbody.id = 'popout-change-log-table';
//         if (data.geofence_change_logs){
//           let changeLogs = data.geofence_change_logs;
//           for (let i = 0; i < changeLogs.length; i++){
//             let tr = document.createElement('tr');
//             let previousStartDateTd = document.createElement('td');
//             previousStartDateTd.innerText = changeLogs[i].previous_start_date;
//             let previousEndDateTd = document.createElement('td');
//             previousEndDateTd.innerText = changeLogs[i].previous_end_date;
//             let newStartDateTd = document.createElement('td');
//             newStartDateTd.innerText = changeLogs[i].new_start_date;
//             let newEndDateTd = document.createElement('td');
//             newEndDateTd.innerText = changeLogs[i].new_end_date;
//             let reasonTd = document.createElement('td');
//             reasonTd.innerText = changeLogs[i].reason;
//             let timestampTd = document.createElement('td');
//             timestampTd.innerText = changeLogs[i].timestamp;
//             tr.appendChild(previousStartDateTd);
//             tr.appendChild(previousEndDateTd);
//             tr.appendChild(newStartDateTd);
//             tr.appendChild(newEndDateTd);
//             tr.appendChild(reasonTd);
//             tr.appendChild(timestampTd);
//             newTbody.appendChild(tr);
//           }
//         }
//         changeLogTable.parentNode.replaceChild(newTbody, changeLogTable);
//       }
//     }
//   })
// }
//
function createResourceSelects(resource){
  // When user adds a new resource to the zone / project need to add a row of people or machine selects
  let table = document.getElementById('popout-resources-table');
  // Create outer columns div
  let containingDiv = document.createElement('div');
  containingDiv.className = 'columns is-fullwidth popout-resource-row-div';
  // Create first select (people or machines)
  // Made up of outer column div, select control div (as per all of our forms) and then the select itself
  let selectColumn = document.createElement('div');
  selectColumn.className = 'column is-one-quarter resource-type-select-column'
  let selectDiv = document.createElement('div');
  selectDiv.className = 'select control is-one-fourth'
  let select = document.createElement('select');
  select.className = 'dropdown popout-resource-select';
  // Add event listener to this resource select
  select.addEventListener('change', resourceChange, false);
  let options = ['People', 'Machines'];
  let optionsString;
  for (let i = 0; i < options.length; i++) {
    if (i == 0){
      optionsString += '<option value="' + options[i] + '">' + 'People' + '</option>'
    }else{
      optionsString += '<option value="' + options[i] + '">' + 'Machines' + '</option>'
    }
  }
  // Append the elements to the parent elements
  select.innerHTML = optionsString;
  selectDiv.appendChild(select);
  selectColumn.appendChild(selectDiv);
  containingDiv.appendChild(selectColumn);
  // Do the same again for the second select which is made up of peoples roles
  let peopleSelectColumn = document.createElement('div');
  peopleSelectColumn.className = 'column is-two-quarters resource-specific-select-column'
  let peopleSelectDiv = document.createElement('div');
  peopleSelectDiv.className = 'select control is-fullwidth';
  let peopleSelect = document.createElement('select');
  peopleSelect.className = 'dropdown popout-people-select';
  updateSelectOptions(peopleSelect, "People");
  peopleSelectDiv.appendChild(peopleSelect);
  peopleSelectColumn.appendChild(peopleSelectDiv);
  containingDiv.appendChild(peopleSelectColumn);
  // Add the final column to select the amount of that resource the user wants
  let amountColumn = document.createElement('div');
  amountColumn.className = 'column is-one-quarter resource-amount-column'
  let amount = document.createElement('input');
  amount.type = 'number'
  amount.className = 'amount input'
  amountColumn.appendChild(amount);
  containingDiv.appendChild(amountColumn);
  // Create a delete button
  let deleteColumn = document.createElement('div');
  deleteColumn.className = 'column is-one-quarter resource-delete-column'
  let deleteButton = document.createElement('div');
  deleteButton.className = 'delete-resource button is-danger is-small has-margin-top-20'
  deleteButton.innerHTML = '<i class="fas fa-trash"></i>'
  deleteButton.addEventListener('click', deleteResource, false);
  deleteColumn.appendChild(deleteButton);
  containingDiv.appendChild(deleteButton);
  // Add the containing div to the table
  table.appendChild(containingDiv);
  if (resource) {
    select.value = resource[0];
    if (resource[0] === "Machines") {
      updateSelectOptions(peopleSelect, "Machines");
    }
    peopleSelect.value = resource[1];
    amount.value = resource[2];
  }
}
//
// function deleteResource(){
//   this.parentElement.remove();
// }
//
// // When clicking on either a zone on the map, or a zone in the gantt chart the map should pan to that zone
// function flyToZone(task){
//   $.ajax({
//     type: 'GET',
//     url: `${Config.apiUrl}geofences/get_fence_location?apikey=${Config.apiKey}&site_id=${siteId}&fence_name=${task.name}`,
//     dataType: "json",
//     success: data => {
//       if (data.coordinates){
//         let latitude = data.coordinates.split(", ")[0];
//         let longitude = data.coordinates.split(", ")[1];
//         mapObject.flyTo({
//           center: [
//             latitude,
//             longitude
//           ],
//           zoom: 17,
//           essential: true // this animation is considered essential with respect to prefers-reduced-motion
//         });
//       }
//     }
//   })
// }
//
// async function populateMap(map, range_date_param){
//   // These calls do not change if there is a date or not
//   perimeterAjaxCall(map, null, siteId, true, false);
//   await imageDataAjaxCall(map, geofenceIds, range_date_param, null, false, false, false, true, null, siteId, null, true, false)
// }
//
// function getZonesForChart(startDate, endDate, gantt, site_id, is_first_setup){
//   let site_id_to_use;
//   if (site_id){
//     site_id_to_use = site_id;
//   }else{
//     site_id_to_use = siteId;
//   }
//   $.ajax({
//     type: 'GET',
//     url: `${Config.apiUrl}live/zones_for_gantt?apikey=${Config.apiKey}&site_id=${site_id_to_use}&from_date=${startDate}&to_date=${endDate}`,
//     dataType: "json",
//     success: data => {
//       if (data.zones_data){
//         if(data.zones_data.length > 0 && !is_first_setup){
//           gantt.set_start(startDate);
//           gantt.set_end(endDate);
//           gantt.refresh(data.zones_data);
//           let ganttContainers = document.getElementsByClassName('gantt-container');
//           if (ganttContainers && ganttContainers.length > 1){
//             for (let i = 1; i < ganttContainers.length; i++){
//               ganttContainers[i].setAttribute('style', 'height:100% !important');
//             }
//           }
//           document.getElementById('timeline').setAttribute('style', 'display:none !important');
//           document.getElementById('gantt-menu-bar').setAttribute('style', 'display:block !important');
//           document.getElementById('gantt').setAttribute('style', 'visibility:visible !important');
//           document.querySelector('.gantt-container').setAttribute('style', 'height:20% !important');
//           taskNames = [];
//           data.zones_data.forEach(item => taskNames.push(item.name));
//         }else{
//           if (data.zones_data.length > 0){
//             data.zones_data.forEach(item => taskNames.push(item.name));
//           }
//           document.getElementById('gantt').setAttribute('style', 'visibility:hidden !important');
//           document.querySelector('.gantt-container').setAttribute('style', 'height:0 !important');
//           document.getElementById('gantt-menu-bar').setAttribute('style', 'display:none !important');
//         }
//       }
//     }
//   })
// }
//
// export  { mapObject, geofenceIds, createResourceSelects, resetLayerLines, removeResourceRows };
export  { createResourceSelects, removeResourceRows, savePopoutForm, deleteZone };
//
// // View modes required in the GanttExtension class
// const VIEW_MODE = {
//   QUARTER_DAY: 'Quarter Day',
//   HALF_DAY: 'Half Day',
//   DAY: 'Day',
//   WEEK: 'Week',
//   MONTH: 'Month',
//   YEAR: 'Year'
// };
//
// class GanttExtension extends Gantt {
//
//   constructor(wrapper, tasks, options, startDate, endDate) {
//     super(wrapper, tasks, options);
//     this.start = startDate;
//     this.end = endDate;
//   }
//
//   set_start(startDate){
//     this.start = startDate;
//   }
//
//   set_end(endDate){
//     this.end = endDate;
//   }
//
//   // override to add different amount of days in else block
//   setup_gantt_dates(startDate, endDate) {
//     this.gantt_start = date_utils.parse(this.start);
//     this.gantt_end = date_utils.parse(this.end);
//
//     for (let task of this.tasks) {
//       // set global start and end date
//       if (!this.gantt_start || task._start < this.gantt_start) {
//         this.gantt_start = task._start;
//       }
//       if (!this.gantt_end || task._end > this.gantt_end) {
//         this.gantt_end = task._end;
//       }
//     }
//
//     // add date padding on both sides
//     if (this.view_is([VIEW_MODE.QUARTER_DAY, VIEW_MODE.HALF_DAY])) {
//       this.gantt_start = date_utils.add(this.gantt_start, -7, 'day');
//       this.gantt_end = date_utils.add(this.gantt_end, 7, 'day');
//     } else if (this.view_is(VIEW_MODE.MONTH)) {
//       this.gantt_start = date_utils.start_of(this.gantt_start, 'year');
//       this.gantt_end = date_utils.add(this.gantt_end, 1, 'year');
//     } else if (this.view_is(VIEW_MODE.YEAR)) {
//       this.gantt_start = date_utils.add(this.gantt_start, -2, 'year');
//       this.gantt_end = date_utils.add(this.gantt_end, 2, 'year');
//     } else {
//       if (this.start === null){
//         this.gantt_start = date_utils.add(this.gantt_start, -7, 'day');
//         this.gantt_end = date_utils.add(this.gantt_end, 7, 'day');
//       }
//     }
//   }
//
//   update_view_scale(view_mode) {
//     this.options.view_mode = view_mode;
//
//     if (view_mode === VIEW_MODE.DAY) {
//       this.options.step = 24;
//     } else if (view_mode === VIEW_MODE.HALF_DAY) {
//       this.options.step = 24 / 2;
//       this.options.column_width = 38;
//     } else if (view_mode === VIEW_MODE.QUARTER_DAY) {
//       this.options.step = 24 / 4;
//       this.options.column_width = 38;
//     } else if (view_mode === VIEW_MODE.WEEK) {
//       this.options.step = 24 * 7;
//       this.options.column_width = 140;
//     } else if (view_mode === VIEW_MODE.MONTH) {
//       this.options.step = 24 * 30;
//       this.options.column_width = 120;
//     } else if (view_mode === VIEW_MODE.YEAR) {
//       this.options.step = 24 * 365;
//       this.options.column_width = 120;
//     }
//   }
//
//   // pass the gantt_start into the constructor as this wasn't updating correctly
//   make_bars() {
//     this.bars = this.tasks.map(task => {
//       const bar = new BarExtended(this, task, this.gantt_start);
//       this.layers.bar.appendChild(bar.group);
//       return bar;
//     });
//   }
// }
//
// class BarExtended extends Bar{
//
//   // set the start date manually as at times this was referencing a different date when calling this.gantt.gantt_start
//   constructor(gantt, task, start_date) {
//     super(gantt, task);
//     this.start = start_date;
//   }
//
//   set_start(start){
//     this.start = start;
//   }
//
//   // override to use this.start instead of this.gantt.gantt_start
//   compute_start_end_date() {
//     const bar = this.$bar;
//     const x_in_units = bar.getX() / this.gantt.options.column_width;
//     const new_start_date = date_utils.add(
//         this.start,
//         x_in_units * this.gantt.options.step,
//         'hour'
//     );
//     const width_in_units = bar.getWidth() / this.gantt.options.column_width;
//     const new_end_date = date_utils.add(
//         new_start_date,
//         width_in_units * this.gantt.options.step,
//         'hour'
//     );
//
//     return { new_start_date, new_end_date };
//   }
// }