document.addEventListener("turbolinks:load", function() {
  listenToUserRoleSelection();
})

function listenToUserRoleSelection() {
  $("#user_user_role_id").change(function() {

    var selectedText = $(this).find(':selected').text();
    var $companySelect = $("#company-select");

    if (selectedText != "Super Admin") {
      showElement($companySelect);
    } else {
      hideElement($companySelect);
    }
  });
}

function hideElement(element) {
  if (!element.hasClass("is-hidden")) {
    element.addClass("is-hidden");
  }
}

function showElement(element) {
  element.removeClass("is-hidden");
}
