import Config from "./config/constants";

$(document).on('turbolinks:load', () => {
    var orderForm = document.getElementById('edit-order-form');
    if (orderForm !== null) {
        var companySelector = document.getElementById('order_company_id');
        var siteSelector = document.getElementById('order_site_id');

        companySelector.addEventListener('change', function() { listenForCompanyChange(companySelector, siteSelector) })
    }
});

function listenForCompanyChange(companySelector, siteSelector) {
    // Ask the controller for this company's sites
    let companyId = companySelector.value;
    $.ajax({
        type: 'GET',
        url: `${Config.apiUrl}companies/get_sites_for_company?apikey=${Config.apiKey}&company_id=${companyId}`,
        dataType: "json",
        success: data => {
            siteSelector.textContent = '';
            // Add a 'not going to a site' option first
            let opt = document.createElement('option');
            opt.value = "Not going to a site";
            opt.innerHTML = "Not going to a site";
            siteSelector.appendChild(opt);

            // Add the sites as options
            $.each(data.sites, (index, site) => {
                let opt = document.createElement('option');
                opt.value = site["id"];
                opt.innerHTML = site["name"];
                siteSelector.appendChild(opt);
                // This is needed to automatically update the latlongs to those of the first site
                siteSelector.dispatchEvent(new Event('change'));
            });
        },
        error: data => {
            alert("Error trying to retrieve site data. Please refresh the page and try again.");
        }
    });
}