import Config from "./config/constants";
import videojs from "video.js";

const AWS = require('aws-sdk');

$(document).on('turbolinks:load', function(){
  // this method will be a generic method for handling any modal on any page
  // it will however not be able to handle multiple modals on one page
  // all you need to do is: add the ID `modal-activator` to the element you want to activate the modal when clicked
  handleModal();
  if($("#machine-modal")){
    checkMachineModalForm();

    $(".machine-modal-listener").on("change", function() {
      checkMachineModalForm();
    })
  }
});

function checkMachineModalForm() {
  let machine_id_field = $("#machine-modal-machine-tag-control-id").val();
  let date_from_field = $(".date-from-picker").val();
  let date_to_field = $(".date-to-picker").val();
  let sites_field = $("#modal-sites").val();
  let submit_button = $("#machine-modal-submit");

  submit_button.prop('disabled', machine_id_field == null || date_from_field == null || date_from_field == "" || date_to_field == null || date_to_field == "" || sites_field == null);
}

function handleModal() {
  var modal = $(".modal");
  $("#modal-activator").click(function (e) {
    //activate modal
    modal.addClass("is-active");
  });
  $(".modal-background, #modal-close").click(function (e) {
    //close modal
    modal.removeClass("is-active");
  });

  var machine_zone_modal = $("#machine-zone-interaction-modal");
  $("#machine-modal-activator").click(function (e) {
    //activate modal
    machine_zone_modal.addClass("is-active");
  });
  $(".modal-background, #modal-close").click(function (e) {
    //close modal
    machine_zone_modal.removeClass("is-active");
  });

  var webhook_modal = $("#webhook-modal");
  $("#webhook-modal-activator").click(function (e) {
    //activate modal
    webhook_modal.addClass("is-active");
  });
  $(".webhook-modal-background, #webhook-modal-close").click(function (e) {
    //close modal
    webhook_modal.removeClass("is-active");
  });

  $(".machine-modal-activator").on("click",function () {
    $(".date-from-picker").val("");
    $(".date-to-picker").val("");
    $("#machine-modal-submit").prop('disabled', true);

    $("#machine-modal-machine-tag-control-id").val($(this).data('value'));
    if($(this).data('company-id')){
      let companyId = $(this).data('company-id');
      let siteDropdown = $("#modal-sites");

      $.ajax({
        type: 'GET',
        url: `${Config.apiUrl}companies/get_sites_for_company?apikey=${Config.apiKey}&company_id=${companyId}`,
        dataType: "json",
        success: data => {
          let siteDropdownHtml = "";
          $.each(data.sites, (index, site) => {
            siteDropdownHtml +=  `<option value="${site["id"]}">${site["name"]}</option>`
          });
          siteDropdown.empty().append(siteDropdownHtml);
        },
        error: data => {
          alert("Error trying to retrieve site data. Please refresh the page and try again.");
        }
      });
    }
    modal.addClass("is-active");
  });

  let eventModal = $("#event-video-modal");
  $(".event-video-button").click(function (e) {
    var player = videojs(document.querySelector('.video-js'));
    let file_name = $(this).data('video-url');

    $.ajax({
      url: `${Config.apiUrl}files/aws_s3_key?apikey=${Config.apiKey}`,
      type: "GET",
      success: async data => {
        var params = {
          Bucket: 'plinx-cctv-clips',
          Key: file_name,
        };

        let access_key_id = document.getElementById('s3_access_key_id').value;
        let region = document.getElementById('region').value;

        var s3 = new AWS.S3({
            accessKeyId: access_key_id,
            secretAccessKey: data.key,
            region: region,
        });

        var promise = s3.getSignedUrlPromise('getObject', params);
        promise.then(
            function (url) {
              player.src({
                src: url,
                type: 'video/mp4' /*video type*/,
              });
            },
            function (err) {
              console.log(err);
            }
        );

        //activate modal
        eventModal.addClass("is-active");
      }
    })
  });
}
