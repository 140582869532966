import Config from "./config/constants";

$(document).on('turbolinks:load', function(){
  handleModal();
});

function handleModal() {
  var modal = $(".modal");

  $(".api-key-modal-activator").click(function (e) {
    let company_id = $(this).data("value")
    console.log(company_id)
    $.ajax({
      type: 'GET',
      url: `${Config.apiUrl}companies/get_admin_api_key?apikey=${Config.apiKey}&company_id=${company_id}`,
      dataType: "json",
      success: data => {
        // process the data for the modal, null check each attribute and append to the HTML element it relates to
        let key = data.decrypted_key == null ? "N/A" : data.decrypted_key;
        $('.decrypted-key').empty().append(key);

        modal.addClass("is-active");
      },
      error: data => {
        alert("Error trying to retrieve admin api key. Please refresh the page and try again.");
      }
    });
  });

  $(".modal-background, #modal-close").click(function (e) {
    //close modal
    modal.removeClass("is-active");
  });
}
