import Config from "../custom/config/constants";
import Swal from "sweetalert2";

const AWS = require('aws-sdk');
const mbxClient = require('@mapbox/mapbox-sdk/lib/browser/browser-client');
const mbxUploads = require('@mapbox/mapbox-sdk/services/uploads');
const mbxTilsets = require('@mapbox/mapbox-sdk/services/tilesets');
const baseClient = mbxClient({accessToken: Config.mapboxManagementToken});
const uploadService = mbxUploads(baseClient);
const tileSetService = mbxTilsets(baseClient);
const DOMParser = require("xmldom").DOMParser;
const toGeo = require("@tmcw/togeojson");

document.addEventListener("turbolinks:load", function() {
    // slightly different to submit, just want to show hidden fields based on file type
    listenToFileSelect();
    listenToFileSubmit();
    listenToFileDelete();
    listenToSliderChange();
    listenToColorChange();
    listenToIsGeofence();
    listenToZoneType();
    listenToSiteOrSector();
})

function listenToColorChange(){
    $(".line_color_picker").change(function() {
        let color_val = $(".line_color_picker").val().toString();
        $(".line_example").css("background-color", color_val);
    });
}

function listenToSliderChange(){
    $(".line_width_slider").change(function() {
        let slider_text =  $("#slider_value");
        let line_example = $(".line_example");
        let slider_val = $(".line_width_slider").val().toString();
        let color_val = $(".line_color_picker").val().toString();
        slider_text.empty();
        slider_text.append("Current Value: " + slider_val);
        line_example.css("background-color", color_val);
        line_example.css("height", slider_val);
    });
}

function listenToFileSelect(){
    let fileCustomisation = $("#file-customisation");
    let geojsonTypeSelect = $("#geojson-type-select");

    $("#fileNameId").change(function(e) {
        let fileName = this.value
        let fileExt = fileName.split(".")[1].toUpperCase();
        if (fileExt === "KML"){
            fileCustomisation.show();
            geojsonTypeSelect.hide();
        } else if (fileExt === "GEOJSON") {
            geojsonTypeSelect.show();
        }else{
            fileCustomisation.hide();
            geojsonTypeSelect.hide();
        }
    });
}

function listenToZoneType() {
    $('#file-productivity-hazard-select').change(function () {
        let zoneType = $(this).find(':selected').text();
        if (zoneType === "Productivity Zone") {
            $('#file-access-level-select').hide();
        }
        else {
            $('#file-access-level-select').show();
        }
    });
}

function listenToSiteOrSector() {
    $('#file_area_type').change(function () {
        let type = $(this).find(':selected').text();
        let siteDropdown = $('#file-site-select');
        let sectorDropdown = $('#file-sector-select');
        if (type === "Site") {
            siteDropdown.show();
            sectorDropdown.hide();
        }
        else {
            siteDropdown.hide();
            sectorDropdown.show();
        }
    });
}

function listenToIsGeofence(){
    $("#geojson_selector").change(function() {
        var selectedText = $(this).find(':selected').text();
        if (selectedText === "Line"){
            $("#file-customisation").show();
            $('#file-access-level-select').hide();
            $('#file-productivity-hazard-select').hide();
        }
        else if (selectedText === "Zone") {
            $('#file-access-level-select').show();
        }
        else {
            $("#file-customisation").hide();
            $('#file-access-level-select').hide();
            $('#file-productivity-hazard-select').hide();
        }
    });
}

/**
 *  Listener function for deleting a tilset from mapbox
 */
function listenToFileDelete() {
    $( ".file-delete" ).click(function() {
        var name = $(this).data('fileName');
        tileSetService.deleteTileset({tilesetId: `sroberts95.${name}`
        })
            .send()
            .then(response => {
                const deleted = response.statusCode === 204;
            })
    });
}

/**
 * Listener function for uploading a new file to mapbox
 */
function listenToFileSubmit() {
    $("#upload").submit(function(e) {
        let file = e.target.uploadFile.files[0];
        let siteId = document.getElementById('site_id').value;
        let sectorId = document.getElementById('sector_id').value;
        let siteSectorSelectorValue = document.getElementById('file_area_type').value;
        if (siteSectorSelectorValue == 'Sector') {
            siteId = null;
        }else{
            sectorId = null;
        }
        // Check if the file name has more than one full stop in (we don't allow this just for ease of file splitting)
        let splitFileName = file.name.split(".");
        let fileExt = splitFileName[splitFileName.length - 1];
        let fileNameIsOK = validateFileName(file.name, fileExt);
        let geojsonSelector = $("#geojson_selector").find(':selected').text();
        // start upload to mapbox if the file isnt a geojson which should become a geofence or perimeter as these dont need uploading
        if (fileNameIsOK){
            console.log("File name is ok")
            if (fileExt.toLowerCase() === "tif" || fileExt.toLowerCase() === "tiff"){
                console.log("Is a TIF")
                let companyId = document.getElementById('company_id').value;
                let isTunnel = $('#is_tunnel').find(":selected").text();
                let zLevel = "0"
                if (isTunnel === "Yes") {
                  zLevel = "-1"
                }
                let nextFileId = document.getElementById('next_file_id').value;
                let fileName = companyId + "_" + zLevel + "_" + nextFileId;

                $("#non-tiff-submit").prop("disabled", true);
                $(document.body).css({'cursor' : 'wait'});
                uploadToMapboxS3(file, fileName, siteId, "raster").then(r => r);
            }else{
                if (fileExt !== "geojson" || (fileExt === "geojson" && geojsonSelector === "Line")){
                    if(file.name.split(".")[1] === "kml"){
                        const reader = new FileReader();
                        reader.readAsText(file);
                        // convert kml to geojson as this allows to apply properties to the file later such as line color
                        reader.onload = function(event) {
                            let parser = new DOMParser();
                            let xmlDoc = parser.parseFromString(event.target.result,"text/xml");
                            let geoJson = toGeo.kml(xmlDoc);
                            let b = new Blob([JSON.stringify(geoJson)], {type : "application/json"});
                            uploadToMapboxS3(b, file.name, siteId, "kml").then(r => r);
                        }
                    }else{
                        uploadToMapboxS3(file, file.name, siteId, "geojson").then(r => r);
                    }
                }
            }
        }else{
            console.log("Filename not ok")
            $(document.body).css({'cursor' : 'default'});
        }
    });
}

function redirectAfterFileUpload(fileName, companyId){
    // Check if the person is uploading a file from the company admin screen or the super admin screen
    let isCompanyAdminUrl = false;
    let current_address = window.location.href;
    if (current_address.indexOf('company_admin') > -1){
        isCompanyAdminUrl = true;
    }
    let url = '';
    // Set the url based on if we're in the company admin screen or super admin screen
    if (isCompanyAdminUrl){
        url = window.location.origin + '/company_admin/files/add_file?file_name=' + fileName;
    }else {
        url = window.location.origin + '/super_admin/companies/add_file?file_name=' + fileName + '&company_id=' + companyId;
    }
    window.location.replace(url);
}

function validateFileName(fileName, fileExt){
    let isValidName = false;
    let firstIndexOfDot = fileName.indexOf(".");

    if (fileExt.toLowerCase() === "tif" || fileExt.toLowerCase() === "tiff"){
        if(firstIndexOfDot == fileName.lastIndexOf(".")){
            isValidName = true;
        }
    }else{
        // basic filename validation, to be replaced in future with regex if need be
        if(firstIndexOfDot == fileName.lastIndexOf(".") && !fileName.includes(" ") && !fileName.includes("(") && !fileName.includes(")")){
            isValidName = true;
        }
        if(fileName.split(".")[0].length > 20){
            isValidName = false;
        }
    }
    return isValidName;
}

/**
 * First we need some temporary credentials for Mapbox AWS S3 bucket
 * @param file
 * @param fileName
 * @param siteId
 * @returns {Promise<void>}
 */
async function uploadToMapboxS3(file, fileName, siteId, fileType){
    let companyId = document.getElementById('company_id').value
    let fileExt = fileName.split(".")[1]
    fileName = companyId + "_" + fileName.split(".")[0]
    uploadService
        .createUploadCredentials()
        .send()
        .then(response => {response.body;
            putFileOnS3(response.body, file, fileExt).then(r => uploadToMapbox(response.body, fileName, file.name, fileExt, companyId, siteId, fileType))});
}

/**
 * Before uploading to mapbox, the file has to be staged in Mapbox S3 bucket
 * @param credentials
 * @param file
 * @param fileExt
 * @returns {Promise<void>}
 */
async function putFileOnS3(credentials, file, fileExt) {
    // Create s3 connection using credentials, set timeout to 0 to stop timeout on larger files
    const s3 = new AWS.S3({
        accessKeyId: credentials["accessKeyId"],
        secretAccessKey: credentials["secretAccessKey"],
        sessionToken: credentials["sessionToken"],
        region: 'us-east-1',
        httpOptions: {timeout: 0}
    });
    return s3.putObject({
        Bucket: credentials["bucket"],
        Key: credentials["key"],
        Body: file
    }).promise();
}

/**
 * After staging the file on Mapbox S3, the upload for it can then be kicked off
 * @param credentials
 * @param tilesetName
 * @param fileExt
 * @param companyId
 * @param userId
 * @param siteId
 * @returns {Promise<PromiseResult<S3.PutObjectOutput, AWSError>>}
 */
async function uploadToMapbox(credentials, tilesetName, originalFileName, fileExt, companyId, siteId, fileType) {
    const uploadRequest = uploadService.createUpload({
        tileset: `sroberts95.${tilesetName}`,
        url: credentials["url"],
        name: `${tilesetName}_upload`
    });
    // Uploads from the API cannot have private in the body
    delete uploadRequest.body.private;
    // After uploading, store the upload ID so that we can later refer to it to check the status
    const uploadRes = uploadRequest.send().then(response => {
        const upload = response.body;
        const startDate = document.getElementById('start_date').value;
        const endDate = document.getElementById('end_date').value;
        $.ajax({
            url: `${Config.apiUrl}files/update_image?apikey=${Config.apiKey}`,
            data: {
                "name": tilesetName,
                "description": originalFileName,
                "upload_id": upload.id,
                "company_id": companyId,
                "start_date": startDate,
                "end_date": endDate,
                "use_mapbox": true,
                "file_type": fileType,
                "line_width": document.getElementById('line_width').value,
                "line_color": document.getElementById('line_color').value,
                "site_id": siteId,
            },
            type: "POST",
            success: data => {
                $(document.body).css({'cursor' : 'default'});
                Swal.fire({
                    title: 'Upload successful. Imagery such as TIFs may still take some time to process before appearing on the map',
                    showCancelButton: false
                }, function() {
                    $("#non-tiff-submit").prop("disabled", false);
                })
            }
        });
    });
}