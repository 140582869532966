$(document).on('turbolinks:load', function(){
    $('.notification').delay(5000).fadeOut();

    $(".super-admin-connect-as").click(function () {
        $(".progress-bar").show();
    });
    $(".company-dashboard-load").click(function () {
        $(".progress-bar").show();
    });
    $(".site-dashboard-load").click(function () {
        $(".progress-bar").show();
    });
    $('.map-load').click(function() {
        $('.progress-bar').show();
    });
    $('#map-filter').click(function() {
        $('.progress-bar').show();
    });
    $('.zone-data-load').click(function() {
        $('.progress-bar').show();
    });
    $('.company-dashboard-filter').click(function() {
        $('.progress-bar').show();
    });
    $('.event-log-filter').click(function() {
        $('.progress-bar').show();
    });
    $("#update-now-button-yes").click(function() {
        $(".progress-bar-force-update").show();
    });
});