$(document).on('turbolinks:load', () => {
    if ($('#group-role-select').length > 0) {
        $('#group-role-select').selectize({
            plugins: ['remove_button']
        });
        var roleOrUserSelect = document.getElementById('role-or-user-select');
        listenForRoleOrUserSelect(roleOrUserSelect);
    }
});

const listenForRoleOrUserSelect = (roleOrUserSelect) => {
    var $roleSelect = $('#roles-select');
    var $userSelect = $('#users-select');
    $roleSelect.hide();
    $userSelect.hide();
    roleOrUserSelect.addEventListener("click", function() {
       if (roleOrUserSelect.value === "Users") {
           $roleSelect.hide();
           $userSelect.show();
       }
       else if (roleOrUserSelect.value === "Role") {
            $roleSelect.show();
            $userSelect.hide();
       }
       else {
           $roleSelect.hide();
           $userSelect.hide();
       }
    });
}