// ---------------------------------------------------------------------------
// Not currently used, but will be when users can update their devices on site
// ---------------------------------------------------------------------------
import Config from "../custom/config/constants";

// TODO: Ask the server in 16 minute intervals for the progress of the force update (need Lior to set this up too)

$(document).on('turbolinks:load', () => {
    const $updateNowButton = $('#update-now-button');
    if ($updateNowButton.length) {
        const $yesButton = $('#update-now-button-yes');
        const $noButton = $('#update-now-button-no');
        const $warningMessage = $('#warning-message');
        const $stopUpdateButton = $('#stop-update-button');
        $warningMessage.hide();

        listenForUpdateButtonClick($updateNowButton, $warningMessage);
        listenForYesButtonClick($yesButton, $warningMessage, $updateNowButton, $stopUpdateButton);
        listenForNoButtonClick($noButton, $warningMessage);
        listenForStopUpdateButtonClick($stopUpdateButton, $updateNowButton)
    }
});

const listenForUpdateButtonClick = ($updateNowButton, $warningMessage) => {
    $updateNowButton.click(function (event) {
        $warningMessage.show();
    });
}

const listenForNoButtonClick = ($noButton, $warningMessage) => {
    $noButton.click(function(event) {
        $warningMessage.hide();
    });
}

const listenForYesButtonClick = ($yesButton, $warningMessage, $updateNowButton, $stopUpdateButton) => {
    $yesButton.click(function(event) {
        $warningMessage.hide();
        let siteId = $('.site-dash-site-dropdown').val();
        let userId = $('#user_id').val();

        // Clicking this multiple times crashes mqtt
        // For some reason disabling this with .prop() or .attr() doesn't work, so replace with something else
        $updateNowButton.replaceWith("<p id=\"force-update-text\">Forcing update...</p>");

        // Show the stop update button, so the user can always stop a forced update
        $stopUpdateButton.show();
        $('#update-stopped-text').remove();

        $.ajax({
            type: 'POST',
            url: `${Config.apiUrl}updates/update_devices_on_site?apikey=${Config.apiKey}&site_id=${siteId}&user_id=${userId}`,
            async: true,
            beforeSend: function () {
                $("#coverScreen").css("display", "block");
            },
            success: data => {
                $('#force-update-text').replaceWith("<p>Update sent</p>");
            },
            error: function() {
                $yesButton.css("color", "red");
            }
        });
    });
}

const listenForStopUpdateButtonClick = ($stopUpdateButton) => {
    if ($stopUpdateButton.attr('disabled') !== 'disabled') {
        $stopUpdateButton.click(function(event) {
            let siteId = $('.site-dash-site-dropdown').val();
            let userId = $('#user_id').val();

            // Clicking this multiple times crashes mqtt
            // For some reason disabling this with .prop() or .attr() doesn't work, so replace with something else
            $stopUpdateButton.hide();
            $('#stop-update-wrapper').append("<p id=\"stop-update-text\">Stopping update...</p>");

            $.ajax({
                type: 'POST',
                url: `${Config.apiUrl}updates/stop_update_on_site?apikey=${Config.apiKey}&site_id=${siteId}&user_id=${userId}`,
                async: true,
                beforeSend: function () {
                    $("#coverScreen").css("display", "block");
                },
                success: data => {
                    $(".progress-bar-force-update").hide();
                    $('#stop-update-text').replaceWith("<p id=\"update-stopped-text\">Update stopped</p>")
                },
                error: function() {
                    $stopUpdateButton.css("color", "red");
                }
            });
        });
    }
}