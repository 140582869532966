document.addEventListener("turbolinks:load", function() {
  changeButtonAppearance();
})

function changeButtonAppearance () {
  $('body').on('click', '#sync-device-button', function() { 
    const $syncButton = $("#sync-device-button");
    const confirmationButton = `<button name="button" type="submit" class="button is-success confirm-sync"><span class="icon is-small"><i class="fas fa-check faa-vertical animated"></i></span></button>`
    
    // change button to loading one
    $syncButton.addClass('is-loading');
    setTimeout(function () {
      $syncButton.removeClass('is-loading');
      // add confirmation button before go back to original button
      addConfirmationButton($syncButton, confirmationButton);
    }, 2500);
  });
}

function addConfirmationButton(syncButton, confirmationButton) {  
  syncButton.replaceWith(confirmationButton);
  setTimeout(function () {
    $(".confirm-sync").replaceWith(syncButton);
  }, 1300);
}