$(document).on('turbolinks:load', () => {
    let $showTimelines = $('#show-timelines');
    if ($showTimelines.length) {
        if ($showTimelines.val() === 'false') {
            hideTimelines();
        }
    }
});

const hideTimelines = () => {
    $('#timeline').hide();

}