import Config from "../custom/config/constants";

$(document).on('turbolinks:load', () => {
  const $textField = $("#config-value-text-field");
  const $dropdownField = $("#config-value-dropdown");
  const $configSelect = $("#config-select");
  const $numberSelect = $("#config-value-number-field");

  listenForConfigChange($configSelect, $textField, $dropdownField, $numberSelect);
});

const listenForConfigChange = ($configSelect, $textField, $dropdownField, $numberSelect) => {
  $configSelect.change((e) => {
    let selectValue = $(e.target).val();
    if(selectValue === "GPS Certainty") {
      $numberSelect.show();
      $textField.hide();
      $dropdownField.hide();
    }else if(
          selectValue === "Report Rate" ||
          selectValue === "Spread Factor" ||
          selectValue === "Bump Distance"
    ){
      $textField.show();
      $dropdownField.hide();
      $numberSelect.hide();
    }else {
      $textField.hide();
      $dropdownField.show();
      $numberSelect.hide();
    }
  });
}
