$(document).on('turbolinks:load', () => {
    if(document.getElementById('add-competency-button')){
        document.getElementById('add-competency-button').onclick = () => {
            console.log("HELLO");

            let count = document.querySelectorAll('.input.date-picker-no-time.flatpickr-input').length + 1;

            let container = document.getElementById('manual-competencies-section');

            let columns = document.getElementById('competencies_columns')
            let clone = columns.cloneNode(true);

            let date_picker_options = {
                dateFormat: "F, d Y H:i",
                defaultHour: 0
            }

            let date_picker = clone.children[1].children[0].children[1].children[0];
            date_picker.id = `${date_picker.id}${count}`

            console.log(count);

            container.appendChild(clone);
            $(date_picker).on('focus',function(){
                $(this).flatpickr(date_picker_options);
            });
        }
    }
});