import Config from "../custom/config/constants";

$(document).on('turbolinks:load', () => {
    const $tunnelTagId = $('#tunnel_tag_tunnel_tag_id');
    if ($tunnelTagId.length) {
        listenForTUTCChange();
    }
});

const listenForTUTCChange = () => {
    // When we change site on the Site Dashboard, update the live information for that site
    let $tunnelTagControlField = $('#tunnel_tag_tunnel_tag_control_id');
    let tunnelSelector = document.getElementById('tunnel_tag_tunnel_id')
    $tunnelTagControlField.change((e) => {
        let tutc_id = $tunnelTagControlField.val();
        if (tutc_id) {
            $.ajax({
                type: 'GET',
                url: `${Config.apiUrl}tunnels/get_tunnels_for_tunnel_tag_control?apikey=${Config.apiKey}&tunnel_tag_control_id=${tutc_id}`,
                dataType: "json",
                success: data => {
                    tunnelSelector.textContent = '';
                    // Add the tunnels as options
                    $.each(data.tunnels, (index, tunnel) => {
                        let opt = document.createElement('option');
                        opt.value = tunnel["id"];
                        opt.innerHTML = tunnel["name"];
                        tunnelSelector.appendChild(opt);
                    });
                },
                error: data => {
                    alert("Error trying to retrieve tunnel data.");
                }
            })
        }
        else {
            tunnelSelector.textContent = '';
        }

/*        $.ajax({
            type: 'GET',
            url: `${Config.apiUrl}dashboard/get_active_users_on_site?apikey=${Config.apiKey}&site_id=${siteId}`,
            dataType: "json",
            success: data => {
                let count = data.active_count
                $countTextHolder.empty().append(count);
            },
            error: data => {
                alert("Error trying to retrieve active users data. Please refresh the page and try again.");
                $countTextHolder.empty().append("0");
            }
        });

        $.ajax({
            type: 'GET',
            url: `${Config.apiUrl}dashboard/get_event_status_for_site?apikey=${Config.apiKey}&site_id=${siteId}`,
            dataType: "json",
            success: data => {
                let value = data.event_status
                if (value === 2){
                    $eventStatusHolder.empty().append("<i class='fas fa-circle has-text-danger'></i>");
                } else if (value === 1){
                    $eventStatusHolder.empty().append("<i class='fas fa-circle has-text-warning'></i>");
                }else {
                    $eventStatusHolder.empty().append("<i class='fas fa-circle has-text-success'></i>");
                }
            },
            error: data => {
                alert("Error trying to retrieve site event status. Please refresh the page and try again.");
            }
        })

        $.ajax({
            type: 'GET',
            url: `${Config.apiUrl}dashboard/get_network_status_for_site?apikey=${Config.apiKey}&site_id=${siteId}`,
            dataType: "json",
            success: data => {
                let value = data.network_status
                $networkStatusHolder.empty().append(value);
            },
            error: data => {
                alert("Error trying to retrieve site network status. Please refresh the page and try again.");
            }
        })*/
    });
}