import mapboxgl from "mapbox-gl";
import Config from "./config/constants";
import MapConfig from "./config/map_config";
import {connectBrokenLostAjaxCall, hazardTagAjaxCall} from "./map/map_ajax";

$(document).on('turbolinks:load', () => {
    var productSelector = document.getElementById('product_type');
    if (productSelector !== null) {
        var perimeterMap = $("#map-column");
        perimeterMap.hide();
        const siteSelector = document.getElementById('site_id');
        // Add event listeners to both the product and site selectors, as the form
        // needs to update on of these being clicked
        productSelector.addEventListener('change', function() { formListener(productSelector, siteSelector) });
        siteSelector.addEventListener('change', function() { formListener(productSelector, siteSelector) });
        listenForDoYouKnowProductId()
    }
});

function formListener(productSelector, siteSelector) {
    var productValue = productSelector.value;
    var perimeterMap = $("#map-column");
    let form = document.getElementById('broken-lost-form')

    // Remove previous labels and text boxes
    let connectDiv = document.getElementById('connect-div');
    let hazardTagDiv = document.getElementById('hazard-tag-div');
    let genericDiv = document.getElementById('generic-div');
    if (connectDiv) {
        connectDiv.remove();
    }
    if (hazardTagDiv) {
        hazardTagDiv.remove();
    }
    if (genericDiv) {
        genericDiv.remove();
    }
    perimeterMap.hide();

    let siteValue = siteSelector.value;
    productValue = productValue.replaceAll("[", "").replaceAll("]", "").replaceAll("\"", "").split(",");

    switch(productValue[0]) {
        case "connect":
            $('#user-know-product-id').hide();
            $('#user-know-worker-id').hide();
            $('#generic-div').remove();
            // Add a site selector if there isn't one already
            if (siteValue && productValue) {
                $.ajax({
                    type: 'GET',
                    url: `${Config.apiUrl}map_sites/get_site_coordinates?apikey=${Config.apiKey}&site_id=${siteValue}`,
                    dataType: 'json',
                    success: data => {
                        // CSS is not sticking from Map.scss for this element, no idea why, doing it manually for now works
                        perimeterMap.css('height', '500px');
                        perimeterMap.css('width', '500px');
                        perimeterMap.css('overflow', 'visible');
                        perimeterMap.show();
                        let coordinates = data.coordinates.split(",")[0].split(" ");
                        coordinates = [parseInt(coordinates[0]), parseInt(coordinates[1])];
                        initiateConnectMap(coordinates, siteValue);
                    }
                });
                // Create connect id text box if there isn't one already
                if (!document.getElementById('connect_id_text_box')) {
                    let label = createLabel(form, "Connect ID");
                    addInputElement(form, label, 'connect-div',
                        'connect_id_text_box');
                    removeAllExceptOne('connect-div');
                }

            }
            break;
        case "hazard_tag":
            $('#user-know-product-id').hide();
            $('#user-know-worker-id').hide();
            $('#generic-div').remove();
            // Add a site selector if there isn't one already
            siteValue = siteSelector.value;
            if (siteValue && productValue) {
                $.ajax({
                    type: 'GET',
                    url: `${Config.apiUrl}map_sites/get_site_coordinates?apikey=${Config.apiKey}&site_id=${siteValue}`,
                    dataType: 'json',
                    success: data => {
                        // CSS is not sticking from Map.scss for this element, no idea why, doing it manually for now works
                        perimeterMap.css('height', '500px');
                        perimeterMap.css('width', '500px');
                        perimeterMap.css('overflow', 'visible');
                        perimeterMap.show();
                        let coordinates = data.coordinates.split(",")[0].split(" ");
                        coordinates = [parseInt(coordinates[0]), parseInt(coordinates[1])];
                        initiateHazardTagMap(coordinates, siteValue);
                    }
                });
                // Create hazard tag id text box if there isn't one already
                if (!document.getElementById('hazard_tag_id_text_box')) {
                    let label = createLabel(form, "Hazard Tag ID");
                    addInputElement(form, label, 'hazard-tag-div',
                        'hazard_tag_id_text_box');
                    removeAllExceptOne('hazard-tag-div');
                }
            }
            break;
        case "hub":
        case "power_box":
        case "machine_tag_proximity_sensor":
        case "proximity":
        case "machine_tag":
            $('#user-know-product-id').hide();
            $('#user-know-worker-id').hide();
            $('#generic-div').remove();
            siteValue = siteSelector.value;
            if (siteValue && productValue) {
                // Only create this element if it doesn't exist already
                if (!document.getElementById('generic_id_text_box')) {
                    let label = createLabel(form, "Product ID");
                    addInputElement(form, label, 'generic-div', 'generic_id_text_box');
                    removeMaps();
                }
            }
            break;
        case "team_tag":
            // Show the 'do you know the product id' select
            $('#user-know-product-id').show();
            siteValue = siteSelector.value;
            break;
        default:
            $('#user-know-product-id').hide();
            $('#user-know-worker-id').hide();
            $('#generic-div').remove();
            perimeterMap.hide();
    }
}

const listenForDoYouKnowProductId = () => {
    $('#user-know-product-id').click(function() {
        let val = $(this).find(':selected').val()
       if (val === "Yes") {
           // Only create this element if it doesn't exist already
           if (!document.getElementById('generic_id_text_box')) {
               let label = createLabel(form, "Product ID");
               let form = document.getElementById('broken-lost-form');
               addInputElement(form, label, 'generic-div', 'generic_id_text_box');
               removeMaps();
           }
           $('#user-know-worker-id').hide();
       }
       else if (val === "No") {
            $('#user-know-worker-id').show();
            $('#generic-div').remove();
       }
       else {
           $('#user-know-worker-id').hide();
           $('#generic-div').remove();
    }
    });
}

const initiateConnectMap = (coordinates, siteId) => {
    removeMaps();

    mapboxgl.accessToken = Config.mapboxAccessToken;
    const map = new mapboxgl.Map({
        container: 'perimeter_map',
        style: 'mapbox://styles/sroberts95/ckjssbjs10v9519ozm81mugy9',
        center: coordinates,
        zoom: MapConfig.previewStartingZoom,
        minZoom: MapConfig.zoomOutMax,
        maxZoom: MapConfig.zoomInMax
    });
    map.addControl(new mapboxgl.FullscreenControl());
    map.addControl(new mapboxgl.NavigationControl());

    // Show connects at this site
    connectBrokenLostAjaxCall(map, siteId)
}

const initiateHazardTagMap = (coordinates, siteId) => {
    removeMaps();

    mapboxgl.accessToken = Config.mapboxAccessToken;
    const map = new mapboxgl.Map({
        container: 'perimeter_map',
        style: 'mapbox://styles/sroberts95/ckjssbjs10v9519ozm81mugy9',
        center: coordinates,
        zoom: MapConfig.previewStartingZoom,
        minZoom: MapConfig.zoomOutMax,
        maxZoom: MapConfig.zoomInMax
    });
    map.addControl(new mapboxgl.FullscreenControl());
    map.addControl(new mapboxgl.NavigationControl());

    // Show hazard tags at this site
    hazardTagAjaxCall(map, siteId)
}

function addInputElement(form, label, fieldDivId, selectDivId) {
    let fieldDiv = document.createElement('div');
    fieldDiv.setAttribute('class', 'field');
    fieldDiv.setAttribute('id', fieldDivId);
    fieldDiv.appendChild(label);
    let selectDiv = document.createElement('input');
    selectDiv.setAttribute('type', 'text');
    selectDiv.setAttribute('class', ' input');
    let nameAtt = "object[" + selectDivId + "]";
    selectDiv.setAttribute('name', nameAtt);
    selectDiv.setAttribute('id', selectDivId);
    fieldDiv.appendChild(selectDiv);
    form.insertBefore(fieldDiv, form.childNodes[9]);
    removeAllExceptOne(fieldDivId);
}

function removeMaps() {
    // Remove the previous map elements if there are any
    let mapboxCanaries = document.getElementsByClassName('mapboxgl-canary');
    let mapboxCanvases = document.getElementsByClassName('mapboxgl-canvas-container');
    let mapboxContainers = document.getElementsByClassName('mapboxgl-control-container');
    for (let i = 0; i < mapboxCanaries.length; i++) {
        mapboxCanaries[i].remove();
        mapboxCanvases[i].remove();
        mapboxContainers[i].remove();
    }
}

function createLabel(form, text) {
    let label = document.createElement('label');
    label.setAttribute('class', 'label required');
    label.innerHTML = text;
    return label;
}

// Removes all divs added by js except for one given in idToKeep
function removeAllExceptOne(idToKeep) {
    let connectDiv = document.getElementById('connect-div');
    let hazardTagDiv = document.getElementById('hazard-tag-div');
    let genericDiv = document.getElementById('generic-div');
    let divsToRemove = [connectDiv, hazardTagDiv, genericDiv];
    for (let i = 0; i < divsToRemove.length; i++) {
        let div = divsToRemove[i];
        // div must be found (truthy) to prevent errors in console
        if (div) {
            if (div.id !== idToKeep) {
                div.remove();
            }
        }
    }
}