const MapConfig = {
  clashIconSize: 0.8,
  lowSignalIconSize: 0.8,
  timeoutIconSize: 0.8,
  atRiskIconSize: 0.8,
  lowBatteryIconSize: 0.8,
  liveLocationIconSize: 1.2,
  locationIconSize: 0.8,
  connectIconSize: 1.4,
  startingZoom: 16,
  previewStartingZoom: 15,
  perimeterStartingZoom: 9,
  zoomOutMax: 10,
  zoomInMax: 22,
  locationFrequency: 10000,
  clashFrequency: 10000,
  username: 'sroberts95',
  regularStyle: 'mapbox://styles/sroberts95/ckjssbjs10v9519ozm81mugy9',
  darkStyle: 'mapbox://styles/mapbox/dark-v10',
  greyStyle: 'mapbox://styles/mapbox/light-v11'
}

export default MapConfig;
