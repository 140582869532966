import Config from "../custom/config/constants";

// Stores the rating given in the feedback form
let rating = null;

// Used for determining a drag vs a click on the plinx widget
const delta = 6;
let startX;
let startY;

$(document).on('turbolinks:load', () => {

    // Commented out for now until we use zendesk or some alternative
    // let helpWidget = document.getElementById('plinx-help-wrapper');
    // let helpButton = document.getElementById('user-feedback-widget-wrapper');
    //
    // if (helpWidget && helpButton) {
    //     $(helpWidget).show();
    //     $(helpWidget).draggable();
    //     let $feedbackForm = $(".user-feedback-form-wrapper");
    //     $('body').append($feedbackForm);
    //     $feedbackForm.hide();
    //     let $options = $('.plinx-help-options');
    //     $options.hide();
    //     let $thankYou = $('.thank-you-for-feedback');
    //     $thankYou.hide();
    //
    //     // Remove the direct help button if the page is not new
    //     let isNew = document.getElementById('is-new');
    //     if (!isNew) {
    //         $('.new-feature-help-button').remove();
    //         // Need to adjust styling
    //         $options.css('top', '-30%');
    //     }
    //
    //     helpButton.addEventListener('mousedown', function (event) {
    //         startX = event.pageX;
    //         startY = event.pageY;
    //     })
    //     helpButton.addEventListener('mouseup', function(event) { listenForWidgetClick(event, helpButton, $options, $thankYou) });
    //     listenForFeedbackFormClose($feedbackForm);
    //     listenForFeedbackFormSubmit($feedbackForm, $thankYou);
    //     listenForGreen();
    //     listenForYellow();
    //     listenForRed();
    //     listenForUserFeedbackButton($options, $feedbackForm);
    // }
});

const listenForGreen = () => {
    let smiley = $('.fa-smile');
    smiley.click(function() {
        rating = 1;
        smiley.css('background-color', 'rgba(0,128,0,0.5)');
        removeSmileyBackground("green");
    });
}

const listenForYellow = () => {
    let smiley = $('.fa-meh');
    smiley.click(function() {
        rating = 0;
        smiley.css('background-color', 'rgba(255,165,0,0.5)');
        removeSmileyBackground("yellow");
    });
}

const listenForRed = () => {
    let smiley = $('.fa-angry');
    smiley.click(function() {
        rating = -1;
        smiley.css('background-color', 'rgba(139,0,0,0.5)');
        removeSmileyBackground("red");
    });
}

function removeSmileyBackground(toKeep) {
    if (toKeep === "green") {
        $('.fa-meh').css('background-color', 'transparent');
        $('.fa-angry').css('background-color', 'transparent');
    }
    else if (toKeep === "yellow") {
        $('.fa-smile').css('background-color', 'transparent');
        $('.fa-angry').css('background-color', 'transparent');
    } else {
        $('.fa-smile').css('background-color', 'transparent');
        $('.fa-meh').css('background-color', 'transparent');
    }
}

function listenForWidgetClick(event, helpButton, $options, $thankYou) {
    let diffX = Math.abs(event.pageX - startX);
    let diffY = Math.abs(event.pageY - startY);

    // If the button has not been moved far...
    if (diffX < delta && diffY < delta) {
        if (!$options.is(":visible")) {
            $options.show();
            $options.animate({right: "110%"});
            $thankYou.hide();
        }
        else {
            $options.hide();
            $options.animate({right: "100%"});
            $thankYou.hide();
        }
    }
}

const listenForUserFeedbackButton = ($options, $feedbackForm) => {
    $('.user-feedback-help-button').click(function() {
        $feedbackForm.show();
        $options.hide();
    });
}

const listenForFeedbackFormClose = ($feedbackForm) => {
    $('#user-feedback-close-button').click(function() {
        $feedbackForm.hide();
    });
    $('.fa-times').click(function () {
        $feedbackForm.hide();
    })
}

const listenForFeedbackFormSubmit = ($feedbackForm, $thankYou) => {
    $('#user-feedback-submit-button').click(function() {
        if (rating !== null) {
            let pageName = window.location.href;
            // Remove url prefix
            pageName = pageName.replace('http://127.0.0.1:3000/', '');
            pageName = pageName.replace('https://staging.plinx.io/', '');
            pageName = pageName.replace('https://beta.plinx.io/', '');
            pageName = pageName.replace('https://app.plinx.io/', '');
            // Remove params
            if (pageName.includes('?')) {
                // Get the substring before and including the ?
                pageName = pageName.split('?')[0];
                // Remove the ?
                pageName = pageName.replace('?', '');
            }
            // Remove ids
            pageName = pageName.replace(/[0-9]/g, '');
            // Remove trailing slash '/'
            String.prototype.replaceLast = function (search, replace) {
                return this.replace(new RegExp(search+"([^"+search+"]*)$"), replace+"$1");
            }
            pageName = pageName.replaceLast('/', '');

            let comments = $('#user_feedback_comments').val();
            let userId = $('#user-feedback-current-user').val();

            $.ajax({
                type: 'POST',
                url: `${Config.apiUrl}user_feedback/submit_user_feedback?apikey=${Config.apiKey}&user_id=${userId}&rating=${rating}&comments=${comments}&page_name=${pageName}`,
                dataType: "json",
                success: data => {
                    $thankYou.show();
                }, error: data => {
                    alert("Error sending user feedback. Please refresh the page and try again.");
                }
            });
        }
        else {
            alert("You must select a rating by clicking on one of the faces.");
        }

        $feedbackForm.hide();
    });
}