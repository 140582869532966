import Config from "../custom/config/constants";

$(document).on('turbolinks:load', () => {
  const $siteDropdown = $(".site-dropdown");
  const $userDropdown = $(".user-dropdown");
  const $userDropdownOnMap = $(".user-dropdown-on-map");
  const $geofenceDropdown = $(".geofence-dropdown");
  const $dateFrom = $("#date_from").val();
  const $dateTo = $("#date_to").val();
  if ($siteDropdown.length && $userDropdown.length) {
    listenForSiteChange($siteDropdown, $userDropdown, $dateFrom, $dateTo);
  }
  if ($siteDropdown.length && $userDropdownOnMap.length && $geofenceDropdown.length) {
    listenForSiteChangeOnMap($siteDropdown, $userDropdownOnMap, $geofenceDropdown);
  }
});

const listenForSiteChange = ($siteDropdown, $userDropdown, $dateFrom, $dateTo) => {
  $siteDropdown.change((e) => {
    let siteId = $(e.target).val();

    $.ajax({
      type: 'GET',
      url: `${Config.apiUrl}users?apikey=${Config.apiKey}&site_id=${siteId}&date_from=${$dateFrom}&date_to=${$dateTo}`,
      dataType: "json",
      success: data => {
        let userDropdownHtml = "";
        $.each(data.allocation_data, (index, user_id) => {
          userDropdownHtml +=  `<option value="${user_id}">${user_id}</option>`
        });
        $userDropdown.empty().append(userDropdownHtml);
      },
      error: data => {
        alert("Error trying to retrieve users data. Please refresh the page and try again.");
      }
    });
  });
}

const listenForSiteChangeOnMap = ($siteDropdown, $userDropdown, $geofenceDropdown) => {
  $siteDropdown.change((e) => {
    let siteId = $(e.target).val();
    var $select = $('#user_select').selectize()
    var selectize = $select[0].selectize;
    $.ajax({
      type: 'GET',
      url: `${Config.apiUrl}users?apikey=${Config.apiKey}&site_id=${siteId}`,
      dataType: "json",
      success: data => {
        selectize.clearOptions();
        selectize.clear();
        $.each(data.allocation_data, (index, user_id) => {
          selectize.addOption({
            text: user_id,
            value: user_id
          })
        });
        selectize.refreshOptions();
      },
      error: data => {
        alert("Error trying to retrieve users data. Please refresh the page and try again.");
      }
    });

    $.ajax({
      type: 'GET',
      url: `${Config.apiUrl}geofences?apikey=${Config.apiKey}&site_id=${siteId}`,
      dataType: "json",
      success: data => {
        let geofenceDropdownHtml = "";
        geofenceDropdownHtml +=  `<option value=>None</option>`;
        $.each(data.geofence_data, (index, geofence) => {
          geofenceDropdownHtml +=  `<option value="${geofence.id}">${geofence.name}</option>`
        });
        $geofenceDropdown.empty().append(geofenceDropdownHtml);
      },
      error: data => {
        alert("Error trying to retrieve geofence data. Please refresh the page and try again.");
      }
    });
  });
}
