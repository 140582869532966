import MapConfig from '../config/map_config';
import _ from 'lodash';
import Config from "../config/constants";
import {
    tunnelAjaxCall,
    tunnelTagChainAjaxCall,
    secondToLastTunnelTagAjaxCall,
    fitMapToBounds, imageDataAjaxCall
} from './map_ajax';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as turf from '@turf/turf'

// import * as jsts from 'jsts'
import {getInitialSite} from "./site_management";
let siteCoordinates;
let configureMapObject;

document.addEventListener("turbolinks:load", () => {
    if ($('#tunnel_tag_map').length) {
        $.when(getInitialSite()).done(() => {
            initiatePlotMap();
        })
        saveTunnelTag();
    }
});

const saveTunnelTag = () => {
    $('.geofence-save-button').click(function() {
        let url = window.location.href;
       // Get the coordinates where the tunnel tag has been placed
        let coordinates = $('#tunnel_tag_location').val();
        if (coordinates) {
            url = url.replace("plot", "save_latlong");
            $.ajax({
                type: 'POST',
                url: url,
                data: { 'coordinates' : coordinates },
                dataType: 'json',
                success: function(data) {
                    console.log("saved")
                    let current_url = window.location.href
                    if (current_url.includes('127.0.0.1')) {
                        // Simulate an HTTP redirect:
                        window.location.replace("http://127.0.0.1:3000/super_admin/tunnel_tags");
                    }
                    else if (current_url.includes('staging.plinx.io')) {
                        window.location.replace("https://staging.plinx.io/super_admin/tunnel_tags")
                    }
                    else if (current_url.includes('beta.plinx.io')) {
                        window.location.replace("https://beta.plinx.io/super_admin/tunnel_tags")
                    }
                    else {
                        window.location.replace("https://app.plinx.io/super_admin/tunnel_tags")
                    }
                }
            });
        } else {
            alert("You need to place the tunnel tag before you save it")
        }
    });
}

const initiatePlotMap = () => {
    const tunnelId = document.getElementById('tunnel_id').value;
    siteCoordinates = document.getElementById('site_coordinates').value;
    let siteId = document.getElementById('site_id').value;
    const tunnelTagControlId = document.getElementById('tunnel_tag_control_id').value;
    mapboxgl.accessToken = Config.mapboxAccessToken;
    const map = new mapboxgl.Map({
        container: 'tunnel_tag_map',
        style: MapConfig.darkStyle,
        center: siteCoordinates.split(" "),
        zoom: MapConfig.startingZoom,
        minZoom: MapConfig.zoomOutMax,
        maxZoom: MapConfig.zoomInMax
    });
    fitMapToBounds(map, siteId, siteCoordinates)
    map.addControl(new mapboxgl.FullscreenControl());
    map.addControl(new mapboxgl.NavigationControl());
    map.addControl(new mapboxgl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
            },
            trackUserLocation: true
        })
    );
    let marker = new mapboxgl.Marker();
    //**************************************************
    //**************************************************


    //**************************************************
    //**************************************************
    // MAP EVENT LISTENERS
    map.on('load', async () => {
        console.log(tunnelId);
        await imageDataAjaxCall(map, null, null, null, false, false, true, false, false, siteId, null, false, false, true)
        tunnelAjaxCall(map, tunnelId);
        await secondToLastTunnelTagAjaxCall(map, tunnelTagControlId, tunnelId);
    });
    map.on('click', addMarker);
    // Called when the user clicks on the map to make a point
    function addMarker(e) {
        let coordinates = e.lngLat;
        placeMarkerOnBounds(map, marker, [coordinates.lng, coordinates.lat]);
    }
    //**************************************************
    //**************************************************
    configureMapObject = map;
}

// Places a marker 25m away from the last tunnel tag,
// on the vector between the tunnel tag and user click event
function placeMarkerOnBounds(map, marker, coordinates) {
    let tunnelCircle = $('#tunnel_tag_circle').val();
    // The circle will only be present when there is a tunnel tag already plotted
    if (tunnelCircle && tunnelCircle !== "") {
        tunnelCircle = tunnelCircle.split(",");
        let tunnelCirclePoints = [];
        // Get the points that lie on the circle around the last tunnel tag
        tunnelCircle.forEach((item, index) => {
            if (index % 2 && index < tunnelCircle.length - 2) {
                tunnelCirclePoints.push([parseFloat(tunnelCircle[index + 1]), parseFloat(tunnelCircle[index])]);
            }
        });
        // Sort the points on the circle by how close they are to the user's point
        tunnelCirclePoints.sort(function compare(a, b) {
            if (calculateEuclideanDistance(a, coordinates) < calculateEuclideanDistance(b, coordinates)) {
                return -1
            } else {
                return 1
            }
        })
        // Set the location of the tunnel tag to the point that lies on the circle, closest to the user's point
        marker.setLngLat(tunnelCirclePoints[0]).addTo(map);
        $('#tunnel_tag_location').val(tunnelCirclePoints[0]);
    } else {
        // We don't need to worry about placing the TUT 25m from other TUTs if there are no other TUTs
        marker.setLngLat(coordinates).addTo(map);
        $('#tunnel_tag_location').val(coordinates);
    }
}

// Calculates the euclidean distance between two points
function calculateEuclideanDistance(pointA, pointB) {
    let xDiff = pointA[0]-pointB[0];
    let yDiff = pointA[1]-pointB[1];
    let output = Math.sqrt( Math.pow(xDiff, 2) + Math.pow(yDiff, 2) );
    return output;
}