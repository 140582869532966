document.addEventListener("turbolinks:load", () => {
    if($('#map').length){
        toggleMapLegend();
        toggleMapInfo();
        toggleMapExport();
        toggleMapZones();
    }
});

const toggleMapLegend = () => {
    document.getElementById('map-show-legend-button').onclick = function () {
        if($("#legend-popout-menu").is(":visible")){
            closeMenu('legend-popout-menu');
            $("#map-show-legend-button").removeClass('active');
        }else{
            openMenu('legend-popout-menu');
            $("#map-show-legend-button").addClass('active');
        }
    }
}

const toggleMapInfo = () => {
    const $mapInfoToggleButton = $(".map-info-toggle");
    $mapInfoToggleButton.click((e) => {
        let map_info_container = document.querySelector('.map-info-container');
        map_info_container.classList.remove('animate__slideInRight');
        map_info_container.classList.add('animate__animated', 'animate__slideOutRight',  'animate__faster');
        map_info_container.setAttribute('style', 'visibility:hidden');
    });
}

const toggleMapZones = () => {
    const $mapZoneToggleButton = $("#map-show-zones-button");
    const $mapStatsContainer = $(".stats-menu")
    $mapZoneToggleButton.click((e) => {
        if($mapStatsContainer.is(':visible')){
            $mapStatsContainer.hide();
        }else{
            $mapStatsContainer.show();
        }
    });
}

const toggleMapExport = () => {
    document.getElementById('map-show-export-button').onclick = function () {
        if($("#export-popout-menu").is(":visible")){
            closeMenu('export-popout-menu');
            $("#map-show-export-button").removeClass('active');
        }else{
            openMenu('export-popout-menu');
            $("#map-show-export-button").addClass('active');
        }
    }
}

function openMenu(menu_name){
    $(`#${menu_name}`).css('display', 'flex');
    $(`#${menu_name}`).css('flex-direction', 'column');
}

function closeMenu(menu_name){
    $(`#${menu_name}`).hide();
}

function closePopoutMenus(){
    let popout_menus = document.querySelectorAll('.popout-menu');
    for (var i = 0, len = popout_menus.length; i < len; i++) {
        popout_menus[i].classList.remove('animate__slideInRight');
        popout_menus[i].classList.add('animate__animated', 'animate__slideOutRight',  'animate__faster');
        popout_menus[i].setAttribute('style', 'visibility:hidden');
    }
    $(".popout-menu").hide();
}

export { openMenu, closeMenu }